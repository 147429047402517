<div class="DashboardInner" data-ng-init="checkloggedin()">
  <div class="container">
    <div class="innercontainer">
      <!-------------------------------Header------------------>
      <header class="dashboardHeader">
        <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
            <p class="paraxl fontrobotoblack clrwhite">
              <a routerLink="/home" routerLinkActive="active"
                ><img
                  src="assets/images/dashboard/back.png"
                  class="img-fluid"
                />
                <span>Back</span></a
              >
            </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
            <div class="dsheaderlist">
              <div class="mlist dropdown">
                <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                  <p *ngIf="!loggedin">Login/Sign up</p>
                  <a
                    (click)="triggerModal(modalData)"
                    *ngIf="!loggedin"
                    class="loginClass"
                  >
                    <img src="assets/images/home/login.svg" class="img-fluid" />
                    <span>My Activity</span>
                  </a>
                  <button
                    class="btn btn-outline-primary mr-2"
                    id="dropdownManual"
                    ngbDropdownAnchor
                    (focus)="menu.open()"
                    *ngIf="loggedin"
                  >
                    <p *ngIf="loggedin">Welcome {{ getCustomerName() }}</p>
                    <img src="assets/images/home/login.svg" class="img-fluid" />

                    <span>My Activity</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button ngbDropdownItem>
                      <span
                        ><img
                          src="assets/images/home/drp-icon-2.png"
                          class="img-fluid" /></span
                      ><a routerLink="/mydashboard" routerLinkActive="active"
                        >My Booking</a
                      >
                    </button>
                    <button ngbDropdownItem (click)="logout()">
                      <span
                        ><img
                          src="assets/images/home/drp-icon-3.png"
                          class="img-fluid" /></span
                      >Logout
                    </button>
                  </div>
                </div>

                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#"></a>
                  <a class="dropdown-item" href="#"></a>
                </div>
              </div>
              <div class="dslogo">
                <a
                  class="nav-brand"
                  routerLink="/home"
                  routerLinkActive="active"
                >
                <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!---------------------------------steps-------------------->
      <section class="dsSteps">
        <div class="Stlist">
          <ul class="nav">
            <li class="st-active">
              <a
                routerLink="/services"
                routerLinkActive="active"
                [ngClass]="{ adisabled: !step1 }"
                >01</a
              >
              <span>Select Services</span>
            </li>
            <div class="stline"></div>
            <li>
              <a
                routerLink="/addon"
                routerLinkActive="active"
                [ngClass]="{ adisabled: !step2 }"
                >02</a
              >
              <span>Select Add-on</span>
            </li>
            <div class="stline"></div>
            <li>
              <a
                routerLink="/selectaddress"
                routerLinkActive="active"
                [ngClass]="{ adisabled: !step3 }"
                >03</a
              >
              <span>Address Information</span>
            </li>
            <div class="stline"></div>
            <li>
              <a
                routerLink="/selectservicedate"
                routerLinkActive="active"
                [ngClass]="{ adisabled: !step4 }"
                >04</a
              >
              <span>Select Date / Time</span>
            </li>
            <div class="stline"></div>
            <li>
              <a
                routerLink="/confirmbooking"
                routerLinkActive="active"
                [ngClass]="{ adisabled: !step5 }"
                >05</a
              >
              <span>Confirmation</span>
            </li>
          </ul>
        </div>
        <div class="ds-products">
          <div class="dsTab">
            <ul
              ngbNav
              #nav="ngbNav"
              [(activeId)]="cattabactive"
              class="nav nav-tabs dsproductTabStyle"
              id="dsProducttab"
              role="tablist"
            >
              <li [ngbNavItem]="1" class="nav-item">
                <a class="nav-link" ngbNavLink id="sanitarywareTab">
                  <img
                    src="assets/images/dashboard/sanitaryware-1.png"
                    class="img-fluid"
                  />
                  <span>Sanitaryware</span>
                </a>
                <ng-template
                  ngbNavContent
                  id="sanitarywares"
                  class="tab-pane active block"
                >
                  <div class="dsproductCard card">
                    <div class="card-body">
                      <div class="row">
                        <div class="d-flex vtabflex verticalNav">
                          <ul
                            ngbNav
                            #navsubcat="ngbNav"
                            [(activeId)]="subcattabactive"
                            class="
                              slider-for
                              nav nav-tabs
                              verticalTabstyle
                              springy-text
                            "
                            id="dsVerticaltabs"
                          >
                            <li [ngbNavItem]="1" class="nav-item">
                              <a ngbNavLink id="dsproduct1" class="nav-link"
                                >Water closet (Floor Mount)</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductOne"
                                class="card tab-pane fade show active"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-A"
                                  style="display: none"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-A"
                                      data-parent="#content"
                                      aria-expanded="true"
                                      aria-controls="collapse-A"
                                    >
                                      Water closet (Floor Mount)
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-A"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-A"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Water Closet (Floor Mount)
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let pdt of cat1pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  pdt.image !== null
                                                    ? pdt.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="pdt.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ pdt.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ pdt.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : pdt.price
                                                    }}</span
                                                  >
                                                  <div
                                                    class="
                                                      cartItemDetails
                                                      panelfun
                                                    "
                                                  >
                                                    <button
                                                      class="
                                                        addproductbtn
                                                        btnclick
                                                      "
                                                      *ngIf="
                                                        checkcart(
                                                          pdt.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          pdt.product_id,
                                                          pdt.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : pdt.price,
                                                          pdt.name,
                                                          pdt.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          pdt.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            pdt.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          pdt.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            pdt.product_id,
                                                            pdt.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : pdt.price,
                                                            pdt.name,
                                                            pdt.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="openModal(descModal)"
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="2" class="nav-item">
                              <a ngbNavLink id="dsproduct2" class="nav-link"
                                >Water closet (Wall Mounted)</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductTwo"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-B"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-B"
                                      data-parent="#content"
                                      aria-expanded="true"
                                      aria-controls="collapse-B"
                                    >
                                      Water closet (Wall Mounted)
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-B"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-B"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Water closet (Wall Mounted)
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let pdt1 of cat2pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  pdt1.image !== null
                                                    ? pdt1.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="pdt1.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ pdt1.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ pdt1.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : pdt1.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          pdt1.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          pdt1.product_id,
                                                          pdt1.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : pdt1.price,
                                                          pdt1.name,
                                                          pdt1.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          pdt1.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            pdt1.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          pdt1.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            pdt1.product_id,
                                                            pdt1.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : pdt1.price,
                                                            pdt1.name,
                                                            pdt1.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="
                                                    triggerModal(descModal)
                                                  "
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="3" class="nav-item">
                              <a id="dsproduct3" ngbNavLink class="nav-link"
                                >Wash Basin</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductThree"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-C"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-C"
                                      data-parent="#content"
                                      aria-expanded="false"
                                      aria-controls="collapse-C"
                                    >
                                      Wash Basin
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-C"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-C"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Wash Basin
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let pdt2 of cat3pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  pdt2.image !== null
                                                    ? pdt2.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="pdt2.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ pdt2.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ pdt2.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : pdt2.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          pdt2.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          pdt2.product_id,
                                                          pdt2.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : pdt2.price,
                                                          pdt2.name,
                                                          pdt2.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          pdt2.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            pdt2.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          pdt2.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            pdt2.product_id,
                                                            pdt2.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : pdt2.price,
                                                            pdt2.name,
                                                            pdt2.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="
                                                    triggerModal(descModal)
                                                  "
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="4" class="nav-item">
                              <a id="dsproduct4" ngbNavLink class="nav-link"
                                >Bowl</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductFour"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-D"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-D"
                                      data-parent="#content"
                                      aria-expanded="false"
                                      aria-controls="collapse-D"
                                    >
                                      Bowl
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-D"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-D"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Bowl
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let dpdt of cat10pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  dpdt.image !== null
                                                    ? dpdt.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="dpdt.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ dpdt.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ dpdt.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : dpdt.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          dpdt.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          dpdt.product_id,
                                                          dpdt.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : dpdt.price,
                                                          dpdt.name,
                                                          dpdt.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          dpdt.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            dpdt.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          dpdt.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            dpdt.product_id,
                                                            dpdt.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : dpdt.price,
                                                            dpdt.name,
                                                            dpdt.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="
                                                    triggerModal(descModal)
                                                  "
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="5" class="nav-item">
                              <a id="dsproduct5" ngbNavLink class="nav-link"
                                >Urinal</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductFive"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-B"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-E"
                                      data-parent="#content"
                                      aria-expanded="false"
                                      aria-controls="collapse-B"
                                    >
                                      Urinal
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-E"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-B"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Urinal
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let pdt3 of cat4pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  pdt3.image !== null
                                                    ? pdt3.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="pdt3.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ pdt3.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ pdt3.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : pdt3.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          pdt3.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          pdt3.product_id,
                                                          pdt3.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : pdt3.price,
                                                          pdt3.name,
                                                          pdt3.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          pdt3.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            pdt3.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          pdt3.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            pdt3.product_id,
                                                            pdt3.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : pdt3.price,
                                                            pdt3.name,
                                                            pdt3.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a href="#" class="viewlink"
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="6" class="nav-item">
                              <a id="dsproduct6" ngbNavLink class="nav-link"
                                >Seat Cover</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductSix"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-F"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-F"
                                      data-parent="#content"
                                      aria-expanded="false"
                                      aria-controls="collapse-F"
                                    >
                                      Seat Cover
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-F"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-B"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Seat Cover
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let pdt4 of cat5pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  pdt4.image !== null
                                                    ? pdt4.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="pdt4.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ pdt4.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ pdt4.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : pdt4.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          pdt4.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          pdt4.product_id,
                                                          pdt4.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : pdt4.price,
                                                          pdt4.name,
                                                          pdt4.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          pdt4.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            pdt4.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          pdt4.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            pdt4.product_id,
                                                            pdt4.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : pdt4.price,
                                                            pdt4.name,
                                                            pdt4.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="
                                                    triggerModal(descModal)
                                                  "
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="7" class="nav-item">
                              <a id="dsproduct7" ngbNavLink class="nav-link"
                                >Cistern (Plastic or Ceramics)</a
                              >

                              <ng-template
                                ngbNavContent
                                id="dsProductSeven"
                                class="card tab-pane fade show"
                              >
                                <div
                                  class="card-header"
                                  role="tab"
                                  id="heading-G"
                                >
                                  <h5 class="mb-0">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse-G"
                                      data-parent="#content"
                                      aria-expanded="false"
                                      aria-controls="collapse-G"
                                    >
                                      Cistern (Plastic or Ceramics)
                                    </a>
                                  </h5>
                                </div>
                                <div
                                  id="collapse-G"
                                  class="collapse show"
                                  role="tabpanel"
                                  aria-labelledby="heading-G"
                                >
                                  <div class="card-body">
                                    <div class="productlist">
                                      <p class="paraxl clrblack fontrobotobold">
                                        Cistern (Plastic or Ceramics)
                                      </p>
                                      <div class="row productRow">
                                        <div
                                          class="col-lg-4 col-sm-6 productgrid"
                                          *ngFor="let gpdt of cat11pdts"
                                        >
                                          <div class="productpanel">
                                            <div class="primg">
                                              <img
                                                src="{{
                                                  gpdt.image !== null
                                                    ? gpdt.image
                                                    : defaultimage
                                                }}"
                                                class="img-fluid"
                                              />
                                            </div>
                                            <div
                                              class="bestseller"
                                              *ngIf="gpdt.best_selling"
                                            >
                                              Best Seller
                                            </div>
                                            <div class="prCont">
                                              <div class="prdesc">
                                                <span>{{ gpdt.category }}</span>
                                                <p
                                                  class="para fontrobotomedium"
                                                >
                                                  {{ gpdt.name }}
                                                </p>
                                                <div class="add_product">
                                                  <span
                                                    >&#8377;{{
                                                      inwarrantyflag
                                                        ? 0
                                                        : gpdt.price
                                                    }}</span
                                                  >
                                                  <div class="cartItemDetails">
                                                    <button
                                                      class="addproductbtn"
                                                      *ngIf="
                                                        checkcart(
                                                          gpdt.product_id
                                                        )
                                                      "
                                                      (click)="
                                                        addItemsToCart(
                                                          gpdt.product_id,
                                                          gpdt.category_id,
                                                          inwarrantyflag
                                                            ? 0
                                                            : gpdt.price,
                                                          gpdt.name,
                                                          gpdt.category
                                                        )
                                                      "
                                                    >
                                                      <span>Add</span>
                                                      <img
                                                        src="assets/images/dashboard/plusicon.png"
                                                        class="img-fluid"
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_remove_panel"
                                                      *ngIf="
                                                        !checkcart(
                                                          gpdt.product_id
                                                        )
                                                      "
                                                    >
                                                      <span
                                                        class="itemminus"
                                                        (click)="
                                                          removeItemsFromCart(
                                                            gpdt.product_id
                                                          )
                                                        "
                                                        >-</span
                                                      >
                                                      <span class="qtyText">{{
                                                        getItemQuantity(
                                                          gpdt.product_id
                                                        )
                                                      }}</span>
                                                      <span
                                                        class="itemplus"
                                                        (click)="
                                                          addItemsToCart(
                                                            gpdt.product_id,
                                                            gpdt.category_id,
                                                            inwarrantyflag
                                                              ? 0
                                                              : gpdt.price,
                                                            gpdt.name,
                                                            gpdt.category
                                                          )
                                                        "
                                                        >+</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="details">
                                                <p>Inclusions and Exclusions</p>
                                                <a
                                                  class="viewlink"
                                                  (click)="
                                                    triggerModal(descModal)
                                                  "
                                                  >View Details</a
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                        <div
                          id="content"
                          class="Slickmobilelist verticalTabContent tab-content"
                        >
                          <div [ngbNavOutlet]="navsubcat" class="mt-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" class="nav-item">
                <a class="nav-link" ngbNavLink id="flushingtab">
                  <img
                    src="assets/images/dashboard/Flushing-2.png"
                    class="img-fluid"
                  />
                  <span>Flushing Systems</span>
                </a>
                <ng-template
                  ngbNavContent
                  id="flushingsystems"
                  class="tab-pane"
                >
                  <div class="TabStyle-products dsproductCard card">
                    <div class="card-body">
                      <div class="productlist">
                        <p class="paraxl clrblack fontrobotobold">
                          Flushing Systems (Plastic / Ceramic Tanks / Concealed
                          Cistern)
                        </p>
                        <div class="row productRow">
                          <div
                            class="col-lg-3 col-sm-4 productgrid"
                            *ngFor="let pdt5 of cat6pdts"
                          >
                            <div class="productpanel">
                              <div class="primg">
                                <img
                                  src="{{
                                    pdt5.image !== null
                                      ? pdt5.image
                                      : defaultimage
                                  }}"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="bestseller" *ngIf="pdt5.best_selling">
                                Best Seller
                              </div>
                              <div class="prCont">
                                <div class="prdesc">
                                  <span>{{ pdt5.category }}</span>
                                  <p class="para fontrobotomedium">
                                    {{ pdt5.name }}
                                  </p>
                                  <div class="add_product">
                                    <span
                                      >&#8377;{{
                                        inwarrantyflag ? 0 : pdt5.price
                                      }}</span
                                    >
                                    <div class="cartItemDetails">
                                      <button
                                        class="addproductbtn"
                                        *ngIf="checkcart(pdt5.product_id)"
                                        (click)="
                                          addItemsToCart(
                                            pdt5.product_id,
                                            pdt5.category_id,
                                            inwarrantyflag ? 0 : pdt5.price,
                                            pdt5.name,
                                            pdt5.category
                                          )
                                        "
                                      >
                                        <span>Add</span>
                                        <img
                                          src="assets/images/dashboard/plusicon.png"
                                          class="img-fluid"
                                        />
                                      </button>
                                      <div
                                        class="add_remove_panel"
                                        *ngIf="!checkcart(pdt5.product_id)"
                                      >
                                        <span
                                          class="itemminus"
                                          (click)="
                                            removeItemsFromCart(pdt5.product_id)
                                          "
                                          >-</span
                                        >
                                        <span class="qtyText">{{
                                          getItemQuantity(pdt5.product_id)
                                        }}</span>
                                        <span
                                          class="itemplus"
                                          (click)="
                                            addItemsToCart(
                                              pdt5.product_id,
                                              pdt5.category_id,
                                              inwarrantyflag ? 0 : pdt5.price,
                                              pdt5.name,
                                              pdt5.category
                                            )
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details">
                                  <p>Inclusions and Exclusions</p>
                                  <a
                                    class="viewlink"
                                    (click)="triggerModal(descModal)"
                                    >View Details</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3" class="nav-item">
                <a class="nav-link" ngbNavLink id="tapsfittingtab">
                  <img
                    src="assets/images/dashboard/Taps.png"
                    class="img-fluid"
                  />
                  <span>Taps & Fittings</span></a
                >
                <ng-template ngbNavContent id="tapsfittings" class="tab-pane">
                  <div class="TabStyle-products dsproductCard card">
                    <div class="card-body">
                      <div class="productlist">
                        <p class="paraxl clrblack fontrobotobold">
                          Taps & Fittings
                        </p>
                        <div class="row productRow">
                          <div
                            class="col-lg-3 col-sm-4 productgrid"
                            *ngFor="let pdt6 of cat7pdts"
                          >
                            <div class="productpanel">
                              <div class="primg">
                                <img
                                  src="{{
                                    pdt6.image !== null
                                      ? pdt6.image
                                      : defaultimage
                                  }}"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="bestseller" *ngIf="pdt6.best_selling">
                                Best Seller
                              </div>
                              <div class="prCont">
                                <div class="prdesc">
                                  <span>{{ pdt6.category }}</span>
                                  <p class="para fontrobotomedium">
                                    {{ pdt6.name }}
                                  </p>
                                  <div class="add_product">
                                    <span
                                      >&#8377;{{
                                        inwarrantyflag ? 0 : pdt6.price
                                      }}</span
                                    >
                                    <div class="cartItemDetails">
                                      <button
                                        class="addproductbtn"
                                        *ngIf="checkcart(pdt6.product_id)"
                                        (click)="
                                          addItemsToCart(
                                            pdt6.product_id,
                                            pdt6.category_id,
                                            inwarrantyflag ? 0 : pdt6.price,
                                            pdt6.name,
                                            pdt6.category
                                          )
                                        "
                                      >
                                        <span>Add</span>
                                        <img
                                          src="assets/images/dashboard/plusicon.png"
                                          class="img-fluid"
                                        />
                                      </button>
                                      <div
                                        class="add_remove_panel"
                                        *ngIf="!checkcart(pdt6.product_id)"
                                      >
                                        <span
                                          class="itemminus"
                                          (click)="
                                            removeItemsFromCart(pdt6.product_id)
                                          "
                                          >-</span
                                        >
                                        <span class="qtyText">{{
                                          getItemQuantity(pdt6.product_id)
                                        }}</span>
                                        <span
                                          class="itemplus"
                                          (click)="
                                            addItemsToCart(
                                              pdt6.product_id,
                                              pdt6.category_id,
                                              inwarrantyflag ? 0 : pdt6.price,
                                              pdt6.name,
                                              pdt6.category
                                            )
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details">
                                  <p>Inclusions and Exclusions</p>
                                  <a
                                    class="viewlink"
                                    (click)="triggerModal(descModal)"
                                    >View Details</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="4" class="nav-item">
                <a class="nav-link" ngbNavLink id="wellnesstab">
                  <img
                    src="assets/images/dashboard/welness.png"
                    class="img-fluid"
                  />
                  <span>Wellness</span></a
                >
                <ng-template ngbNavContent id="wellness" class="tab-pane">
                  <div class="TabStyle-products dsproductCard card">
                    <div class="card-body">
                      <div class="productlist">
                        <p class="paraxl clrblack fontrobotobold">
                          Wellness (Bathtubs / Whirlpool / Shower Panels)
                        </p>
                        <div class="row productRow">
                          <div
                            class="col-lg-3 col-sm-4 productgrid"
                            *ngFor="let pdt7 of cat8pdts"
                          >
                            <div class="productpanel">
                              <div class="primg">
                                <img
                                  src="{{
                                    pdt7.image !== null
                                      ? pdt7.image
                                      : defaultimage
                                  }}"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="bestseller" *ngIf="pdt7.best_selling">
                                Best Seller
                              </div>
                              <div class="prCont">
                                <div class="prdesc">
                                  <span>{{ pdt7.category }}</span>
                                  <p class="para fontrobotomedium">
                                    {{ pdt7.name }}
                                  </p>
                                  <div class="add_product">
                                    <span
                                      >&#8377;{{
                                        inwarrantyflag ? 0 : pdt7.price
                                      }}</span
                                    >
                                    <div class="cartItemDetails">
                                      <button
                                        class="addproductbtn"
                                        *ngIf="checkcart(pdt7.product_id)"
                                        (click)="
                                          addItemsToCart(
                                            pdt7.product_id,
                                            pdt7.category_id,
                                            inwarrantyflag ? 0 : pdt7.price,
                                            pdt7.name,
                                            pdt7.category
                                          )
                                        "
                                      >
                                        <span>Add</span>
                                        <img
                                          src="assets/images/dashboard/plusicon.png"
                                          class="img-fluid"
                                        />
                                      </button>
                                      <div
                                        class="add_remove_panel"
                                        *ngIf="!checkcart(pdt7.product_id)"
                                      >
                                        <span
                                          class="itemminus"
                                          (click)="
                                            removeItemsFromCart(pdt7.product_id)
                                          "
                                          >-</span
                                        >
                                        <span class="qtyText">{{
                                          getItemQuantity(pdt7.product_id)
                                        }}</span>
                                        <span
                                          class="itemplus"
                                          (click)="
                                            addItemsToCart(
                                              pdt7.product_id,
                                              pdt7.category_id,
                                              inwarrantyflag ? 0 : pdt7.price,
                                              pdt7.name,
                                              pdt7.category
                                            )
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details">
                                  <p>Inclusions and Exclusions</p>
                                  <a
                                    class="viewlink"
                                    (click)="triggerModal(descModal)"
                                    >View Details</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="5" class="nav-item">
                <a class="nav-link" ngbNavLink id="Geysertab">
                  <img
                    src="assets/images/dashboard/Geycer.png"
                    class="img-fluid"
                  />
                  <span>Geyser</span></a
                >
                <ng-template ngbNavContent id="Geycer" class="tab-pane">
                  <div class="TabStyle-products dsproductCard card">
                    <div class="card-body">
                      <div class="productlist">
                        <p class="paraxl clrblack fontrobotobold">Geyser</p>
                        <div class="row productRow">
                          <div
                            class="col-lg-3 col-sm-4 productgrid"
                            *ngFor="let pdt8 of cat9pdts"
                          >
                            <div class="productpanel">
                              <div class="primg">
                                <img
                                  src="{{
                                    pdt8.image !== null
                                      ? pdt8.image
                                      : defaultimage
                                  }}"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="bestseller" *ngIf="pdt8.best_selling">
                                Best Seller
                              </div>
                              <div class="prCont">
                                <div class="prdesc">
                                  <span>{{ pdt8.category }}</span>
                                  <p class="para fontrobotomedium">
                                    {{ pdt8.name }}
                                  </p>
                                  <div class="add_product">
                                    <span
                                      >&#8377;{{
                                        inwarrantyflag ? 0 : pdt8.price
                                      }}</span
                                    >
                                    <div class="cartItemDetails">
                                      <button
                                        class="addproductbtn"
                                        *ngIf="checkcart(pdt8.product_id)"
                                        (click)="
                                          addItemsToCart(
                                            pdt8.product_id,
                                            pdt8.category_id,
                                            inwarrantyflag ? 0 : pdt8.price,
                                            pdt8.name,
                                            pdt8.category
                                          )
                                        "
                                      >
                                        <span>Add</span>
                                        <img
                                          src="assets/images/dashboard/plusicon.png"
                                          class="img-fluid"
                                        />
                                      </button>
                                      <div
                                        class="add_remove_panel"
                                        *ngIf="!checkcart(pdt8.product_id)"
                                      >
                                        <span
                                          class="itemminus"
                                          (click)="
                                            removeItemsFromCart(pdt8.product_id)
                                          "
                                          >-</span
                                        >
                                        <span class="qtyText">{{
                                          getItemQuantity(pdt8.product_id)
                                        }}</span>
                                        <span
                                          class="itemplus"
                                          (click)="
                                            addItemsToCart(
                                              pdt8.product_id,
                                              pdt8.category_id,
                                              inwarrantyflag ? 0 : pdt8.price,
                                              pdt8.name,
                                              pdt8.category
                                            )
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details">
                                  <p>Inclusions and Exclusions</p>
                                  <a
                                    class="viewlink"
                                    (click)="triggerModal(descModal)"
                                    >View Details</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
        <!---selected items-->
        <div class="slitems" *ngIf="cartHasItems">
          <div class="slitemslist">
            <span>{{ totalitems }}</span>
            <p>&#8377;{{ totalprice }}</p>
          </div>
          <div class="continute">
            <a
              class="continuelink"
              routerLink="/addon"
              (click)="gotopage()"
              routerLinkActive="active"
              >Continue <img src="assets/images/dashboard/continue.png"
            /></a>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
  <div class="footerTop">
    <div class="container">
      <div class="fTopInner text-center">
        <a routerLink="/home" routerLinkActive="active"
          ><img src="assets/images/home/logo.png" class="img-fluid"
        /></a>
        <p class="para">
          Parryware India is a leading manufacturer of bathroom products
          originated from EID Parry, one of the country's oldest and most
          reputed business entities. In 2006, it entered into a joint venture
          with Roca Corporation, Spain, to become a Roca Group subsidiary in
          2008, and turned into a wholly-owned subsidiary of the Roca Group in
          2011.
        </p>
      </div>
    </div>
  </div>
  <div class="footerBtm ">
    <div class="container">
       <div class="row fbtmRow no-gutters">
          <div class="col-lg-8    fbtmenus">
            <ul class="nav menu_footer">
               <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
               <li class="line"></li>
               <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
               <li class="line"></li>
               <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
               <li class="line"></li>
               <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
               <li class="line"></li>
               <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
               <li class="line"></li>
               <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
            </ul>
          </div>
          <div class="col-lg-4  social-grid ">
            <p class="parasmall">Follow us on:</p>
            <ul class="social-menu">
               <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
               <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
            </ul>
          </div>
       </div>
       <!--coprights-->
       <div class="copyright">
          <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
      </div>
    </div>
 </div>
</section>
<ng-template #descModal let-modal>
  <div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mdlist">
      <p class="parasmall fontrobotomedium">Inclusions and Exclusions</p>
      <b>Inclusions</b>
      <ul class="mdcontxt">
        <li>Repair & refixing of the defective unit</li>
        <li>
          Defective material to be given to customer (for Out of warranty
          customers)
        </li>
        <li>Explanation of Parts used & invoice</li>
        <li>
          Multiple visits may be required for installation (Labour charges for
          mutliple visits are included in this price)
        </li>
        <li>
          The estimate is given based on preliminary inspection. The actual
          amount could be higher based on actual work involved
        </li>
        <li>90 days warranty for the workmanship</li>
      </ul>
      <b>Exclusions</b>
      <ul class="mdcontxt">
        <li>
          Products, Spare Parts & other consumables would be charged extra
        </li>
        <li>Additional products / services would be charged extra</li>
        <li>
          Line blockage (related to building) is beyond the scope of repair
        </li>
        <li>Additional masanory / civil / electrical work is not included</li>
        <li>Alteration of Products is not included</li>
        <li>
          In case of Parryware products, if the product is dropped / obsolete,
          equivalent product may be recommended
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-----------------------------Login Popup------------------------>
<ng-template #modalData let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="popupInner">
      <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
      <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
      <form [formGroup]="registerForm" (ngSubmit)="getotp(modalOtp)">
        <p class="popupsubtitle clrblack fontrobotobold mb30">
          Enter your mobile number
        </p>
        <div class="loginpanel">
          <div class="form-group mb30">
            <input
              class="txtbx pop_txtbx"
              (keypress)="keyPress($event)"
              required
              type="text"
              placeholder="Enter your mobile number"
              formControlName="phonenumber"
              [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
            />
            <div
              *ngIf="submitted && f.phonenumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.phonenumber.errors.required">
                Phone number is required
              </div>
              <div
                *ngIf="
                  f.phonenumber.errors.pattern ||
                  f.phonenumber.errors.maxlength ||
                  f.phonenumber.errors.minlength
                "
              >
                Phone number must be at least 10 numbers
              </div>
            </div>
          </div>
          <input
            type="submit"
            class="btn booknow popbtn fullwidth"
            text="Continue"
            value="Continue"
          />
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!--------------------------OTP Screen------------------->
<ng-template #modalOtp let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="popupInner">
      <h2 class="popuptitle clrblack fontrobotobold mb50">
        Enter verification code
      </h2>
      <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
        <div class="otppanel">
          <p class="parasmall clrblack mb30">
            We have sent a 4 digit OTP to your mobile number
          </p>
          <div class="otpformgroup mb30">
            <div class="otpformInner">
              <input
                id="otp1"
                type="text"
                class="txtbx pop_txtbx otptxt"
                appMoveNextByMaxLength
                required
                (keypress)="keyPress($event)"
                minlength="1"
                maxlength="1"
                formControlName="first"
                [ngClass]="{ 'is-invalid': submitted1 && f1.first.errors }"
              />
              <input
                id="otp2"
                type="text"
                class="txtbx pop_txtbx otptxt"
                appMoveNextByMaxLength
                (keypress)="keyPress($event)"
                required
                minlength="1"
                maxlength="1"
                formControlName="second"
                [ngClass]="{ 'is-invalid': submitted1 && f1.second.errors }"
              />
              <input
                id="opt3"
                type="text"
                class="txtbx pop_txtbx otptxt"
                appMoveNextByMaxLength
                (keypress)="keyPress($event)"
                required
                minlength="1"
                maxlength="1"
                formControlName="third"
                [ngClass]="{ 'is-invalid': submitted1 && f1.third.errors }"
              />
              <input
                id="otp4"
                type="text"
                class="txtbx pop_txtbx otptxt"
                (keypress)="keyPress($event)"
                required
                minlength="1"
                maxlength="1"
                formControlName="fourth"
                [ngClass]="{ 'is-invalid': submitted1 && f1.fourth.errors }"
              />
            </div>
            <div *ngIf="submitted1 && f1.first.errors" class="invalid-feedback">
              <div *ngIf="f1.first.errors.required">Otp is required</div>
              <div
                *ngIf="
                  f1.first.errors.pattern ||
                  f1.first.errors.maxlength ||
                  f1.first.errors.minlength
                "
              >
                Otp is not valid
              </div>
              <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->
            </div>
            <div
              *ngIf="submitted1 && f1.second.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f1.second.errors.required">Otp is required</div>
              <div
                *ngIf="
                  f1.second.errors.pattern ||
                  f1.second.errors.maxlength ||
                  f1.second.errors.minlength
                "
              >
                Otp is not valid
              </div>
              <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->
            </div>
            <div *ngIf="submitted1 && f1.third.errors" class="invalid-feedback">
              <div *ngIf="f1.third.errors.required">Otp is required</div>
              <div
                *ngIf="
                  f1.third.errors.pattern ||
                  f1.third.errors.maxlength ||
                  f1.third.errors.minlength
                "
              >
                Otp is not valid
              </div>
              <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->
            </div>
            <div
              *ngIf="submitted1 && f1.fourth.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f1.fourth.errors.required">Otp is required</div>
              <div
                *ngIf="
                  f1.fourth.errors.pattern ||
                  f1.fourth.errors.maxlength ||
                  f1.fourth.errors.minlength
                "
              >
                Otp is not valid
              </div>
              <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->
            </div>
          </div>
          <input
            type="submit"
            class="btn booknow popbtn fullwidth"
            text="Continue"
            value="Continue"
          />
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!--------------------------Name and Email ID------------------->
<ng-template #contactData let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="popupInner">
      <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
      <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
      <form [formGroup]="contactForm" (ngSubmit)="storeContact()">
        <p class="popupsubtitle clrblack fontrobotobold mb30">
          Enter your Name
        </p>
        <div class="loginpanel">
          <div class="form-group mb30">
            <input
              class="txtbx pop_txtbx"
              required
              type="text"
              placeholder="Enter Your Name"
              formControlName="customername"
              [ngClass]="{ 'is-invalid': submitted1 && f2.customername.errors }"
            />
            <div
              *ngIf="submitted2 && f2.customername.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f2.customername.errors.required">
                Customer Name is required
              </div>
              <div *ngIf="f2.customername.errors.pattern">Enter valid name</div>
            </div>
          </div>

          <div class="form-group mb30">
            <input
              class="txtbx pop_txtbx"
              required
              type="text"
              placeholder="Enter Your Email"
              formControlName="customeremailid"
              [ngClass]="{
                'is-invalid': submitted1 && f2.customeremailid.errors
              }"
            />
            <div
              *ngIf="submitted2 && f2.customeremailid.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f2.customeremailid.errors.required">
                Customer Email ID is required
              </div>
              <div *ngIf="f2.customeremailid.errors.pattern">
                Enter valid mail id
              </div>
            </div>
          </div>
          <input
            type="submit"
            class="btn booknow popbtn fullwidth"
            text="Continue"
            value="Continue"
          />
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-----------------------------End of contact details------------------------>
