<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a (click)="NavigateBack()"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span >Back</span></a>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">

            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!--Privacy policy-->
     <div class="Contentpanel clrwhite">
        <h2 class="h3title  clrwhite mb30 robotofontbold">PRIVACY POLICY
        </h2>
        <p class="para mb15">Parryware accords enormous importance to your privacy. This privacy policy explains you what we do with any personal information that you provide to us while using our site. That's why we've created this Privacy Policy, which discloses our information gathering and dissemination practices.
        </p>
        <p class="para mb15">At Parryware we intend to protect the confidentiality and integrity of your personally identifiable information. We do not collect your personal information without your consent. After providing consent, You may choose to give us personal information such as your name, mobile number, demographic information, video recordings and address or e-mail identification address that may be needed for business purposes and for future communications to you. 
        </p>
        <p class="para mb15">
            When you use our websites or apps, we automatically collect and analyze certain information, which includes unique browser identifiers, IP addresses, browser and operating system information, device identifiers, geolocation and other device-specific information, Internet connection information, as well as details about your interactions with websites and apps.
        </p>    
        <p class="para mb15">
            The purpose of information collection may be one or many of the following:
        </p>  
        <ul class="list"> 
            <li>To provide/facilitate purchase of products or rendering of services you've requested; </li> 
            <li>To provide the information to trusted entities who work on behalf of or with Parryware under strict confidentiality agreements. However, these companies do not have any independent right to further share or disseminate this information;</li> 
            <li>To respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims;</li> 
            <li> To share the information in order to investigate, prevent, or take action against any illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law. </li> 
            <li>To share the information in order to protect or enforce Parryware' rights, usage terms, intellectual or physical property or for safety of Parryware or associated parties.</li> 
            <li>To contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests.</li> 
            <li>To conduct research and analysis, including surveys;</li> 
           </ul>
       <p class="para mb15">
        We generally retain information for so long as it is be relevant to the purposes above. Data may persist in copies made for backup and business continuity purposes for additional time. For any of the purposes above, we share information with other members of our corporate family. We also share your information with third-party service providers that perform services on our behalf.
       </p>
     </div>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a ><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm ">
         <div class="container">
            <div class="row fbtmRow no-gutters">
               <div class="col-lg-8    fbtmenus">
                 <ul class="nav menu_footer">
                    <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                    <li class="line"></li>
                    <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                 </ul>
               </div>
               <div class="col-lg-4  social-grid ">
                 <p class="parasmall">Follow us on:</p>
                 <ul class="social-menu">
                    <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                    <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                 </ul>
               </div>
            </div>
            <!--coprights-->
            <div class="copyright">
               <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
           </div>
         </div>
      </div>
    </section>
