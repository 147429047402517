import { OrderStatusModel } from './../order-model';
import { Component, OnInit } from '@angular/core';
import {Router, NavigationExtras} from "@angular/router";
import { DatePipe } from '@angular/common';
import { ApiService } from './../api.service';
import {Title} from "@angular/platform-browser";
export type customerorder= {customer_id:number,orderid:number}

@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.css']
})


export class MydashboardComponent implements OnInit {

  allorderDetail:OrderStatusModel[]=[];
  openorderDetail:OrderStatusModel[]=[];
  closedorderDetail:OrderStatusModel[]=[];
  openordersavailable=false;
  activeId=1;

  constructor(private titleService:Title,private datepipe: DatePipe,private apiservice:ApiService,private router: Router) {
    this.titleService.setTitle("Parryware Bano | My Booking");
   }

  ngOnInit(): void {

    this.getOrderDetail();
  }

  getOrderDetail()
  {
    let custorder:customerorder={
      customer_id:parseInt(localStorage.getItem("CustomerID")),
      orderid : null
            }
    this.apiservice.getOrderDetails(custorder).subscribe((data:any)=>{
      this.allorderDetail=data.orders;
      if(this.allorderDetail!=null)
      {
      if(this.allorderDetail.length>0)
      {
        for(var i=0; i<this.allorderDetail.length; i++)
        {
          if(this.allorderDetail[i].order_status.toLowerCase()==="open")
          {
              this.openorderDetail.push(this.allorderDetail[i]);
              this.openordersavailable=true;
          }
          else
          {
            this.closedorderDetail.push(this.allorderDetail[i]);
          }
        }
      }
      console.log(this.openorderDetail);
          console.log(this.openorderDetail[0].product_details[0]);
      console.log(this.closedorderDetail);
    }
    })
   
  }

  NavigateToOrder(orderid:number)
  {
    let index1=this.allorderDetail.findIndex(obj => obj.order_id===orderid)
    window.sessionStorage.setItem("confirmedorderid",orderid.toString());
    let objOrder=this.allorderDetail[index1];
    console.log(objOrder);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        orderDetail:JSON.stringify(objOrder)
      }
    }
    this.router.navigate(["bookingconfirmed"],navigationExtras);
  }


  NavigateToServices()
  {
    this.router.navigate(["services"])
  }

  
  NavigateToServiceCompleted(orderid:number)
  {
    let index1=this.allorderDetail.findIndex(obj => obj.order_id===orderid)
    let objOrder=this.allorderDetail[index1];
    console.log(objOrder);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        orderDetail:JSON.stringify(objOrder)
      }
    }
    this.router.navigate(["servicecompleted"],navigationExtras);
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }

  getDateFormat(date1:string)
  {
    let date2=new Date(date1);
    return this.datepipe.transform(date2, 'dd/MM/yyyy')
  }
  
  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }


}
