<div class="DashboardInner" >
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a (click)="NavigateBack()" style="cursor: hand;"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span >Back</span></a>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">

            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!--Privacy policy-->
     <div class="Contentpanel clrwhite">
        <h2 class="h3title  clrwhite mb30 robotofontbold">About us
        </h2>
        
        <p class="para mb15">
            Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011. 
        </p>  
        <p class="para mb15">
            The journey began in 1952 when Parryware was started under EID Parry in Ranipet. The company joined hands with Royal Doulton in 1960 and inaugurated a new casting unit in 1976. A change came knocking in 1980 when the Murugappa group took over the Parryware brand. Five years later, in 1985, a revolution was born in the shape of the Cascade range of closets which upgraded Parryware from just a bathroom product manufacturer to a brand that stood for glamorous bathrooms. Roca group took over Parryware in 2006 and EID Parry became a 100 per cent subsidiary of the Roca group in 2010. In 2011 Armani Roca brand was launched in the Indian market. Roca acquired 7 production plants by 2014 that are now equipped with leading infrastructure and technology. Parryware’s growth story continued with increased production prowess (8 plants) and deeper penetration into the Indian market. 
         </p> 
        <p class="para mb15">
            In 2015, Parryware transformed its brand identity to establish itself as India’s leading sanitaryware brand.
        </p> 
        <p class="para mb15">
            Parryware reshaped itself as the ‘Always in Fashion’ brand in 2017 that believed in providing the most innovative and aesthetically designed bathroom solutions to its customers. Presently, Parryware is one of the most trusted and leading trendsetter in the industry, dedicated to best-in-class customer service that delivers world-class bathroom solutions with highest quality standards and unmatched design.

         </p> 
        
        
     </div>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm ">
         <div class="container">
            <div class="row fbtmRow no-gutters">
               <div class="col-lg-8    fbtmenus">
                 <ul class="nav menu_footer">
                    <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                    <li class="line"></li>
                    <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                 </ul>
               </div>
               <div class="col-lg-4  social-grid ">
                 <p class="parasmall">Follow us on:</p>
                 <ul class="social-menu">
                    <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                    <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                 </ul>
               </div>
            </div>
            <!--coprights-->
            <div class="copyright">
               <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
           </div>
         </div>
      </div>
    </section>

