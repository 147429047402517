<div class="DashboardInner">
   <div class="container">
      <div class="innercontainer">
         <!-------------------------------Header------------------>
         <header class="dashboardHeader">
            <div class="row dsRow no-gutters">
               <div class="col-lg-4 dsgrid dsleftpanel">
                  <p class="paraxl fontrobotoblack clrwhite">
                     <a routerLink="/services" routerLinkActive="active"><img src="assets/images/dashboard/back.png"
                           class="img-fluid" />
                        <span>My Booking</span></a>
                  </p>
               </div>
               <div class="col-lg-8 dsgrid dsrightpanel">
                  <div class="dsheaderlist">
                     <div class="mlist dropdown">
                        <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                           <p>{{getCustomerName()}}</p>

                           <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                              (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                              class="img-fluid" />
                              <span>My Activity</span></button>
                           <div ngbDropdownMenu aria-labelledby="dropdownManual">
                              <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                       class="img-fluid" /></span><a routerLink="/mydashboard"
                                    routerLinkActive="active">My Booking</a></button>
                              <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                                       class="img-fluid" /></span>Logout</button>

                           </div>
                        </div>
                     </div>
                     <div class="dslogo">
                        <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                           <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <!---------------------------------My booking panel-------------------->
         <div class="mybookingpanel">
            <div class="bookingTab">
               <ul ngbNav #nav="ngbNav" [(activeId)]="activeId " class="nav nav-tabs picktabStyle bookingTabstyle"
                  id="BookingTab">
                  <li [ngbNavItem]="1">
                     <a class="nav-item nav-link" id="nav-ongoing-tab" ngbNavLink>Ongoing</a>
                     <ng-template ngbNavContent>

                        <div *ngIf="openordersavailable">
                           <p class="paraxl fontrobotobold clrblack mb30">Ongoing Orders</p>
                           <div class="row bktabrow">
                              <div class="col-lg-3 col-sm-4 bkgrid" *ngFor="let objongoing of openorderDetail">
                                 <div class="orderrow">
                                    <div class="bkdesc">
                                       <p class="parasmall mb10">Order ID : {{objongoing.order_id}}</p>
                                       <div class="ongoingprlist">
 <p class="para fontrobotomedium">{{objongoing.product_details[0].category_name}}</p>
                         <p class="para fontrobotomedium">{{objongoing.product_details[0].product_name}}</p>
                        </div>
                                       <p class="bkdate fontrobotomedium requestlist">
                                          {{getDateFormat(objongoing.appointment_date)}}
                                          <span class="green fontrobotobold clrgreen">
                                             Request Ongoing 
                                          </span>
                                          <span *ngIf="objongoing.reschedule_count>0">(Rescheduled)</span>
                                       </p>
                                       <p class="bkdate fontrobotomedium">{{objongoing.appointment_slot}}</p>
                                    </div>
                                    <div class="view-info">
                                       <p class="info-details viewdetbx">
                                          A verified professional will be assigned to you one hour prior to the
                                          scheduled time. You'll be notified through SMS & Email
                                       </p>
                                       <button class="btn parrybtn booknow"
                                          (click)="NavigateToOrder(objongoing.order_id)"><span>View
                                             Details</span></button>
                                    </div>
                                 </div>
                              </div>


                           </div>
                        </div>
                        <div class=" orderpanel" *ngIf="!openordersavailable">
                           <img src="assets/images/dashboard/no-active.svg" class="img-fluid mb30" />
                           <p class="paraxl fontrobotobold clrblack mb30">
                              You don't have any active projects right now
                           </p>

                           <div class="gonextpanel nomargin">
                              <button class="gonextbtn btn booknow popbtn fullwidth"
                                 (click)="NavigateToServices()"><span>Book a service</span>
                              </button>
                           </div>
                        </div>
                     </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                     <a class="nav-item nav-link" id="nav-history-tab" ngbNavLink>History</a>
                     <ng-template ngbNavContent>
                        <p class="paraxl fontrobotobold clrblack mb30">Order History</p>
                        <div class="row bktabrow">
                           <div class="col-lg-3 col-sm-4 bkgrid" *ngFor="let objorder1 of closedorderDetail">
                              <div class="orderrow" *ngIf="objorder1.order_status.toLowerCase().includes('closed')"> 
                                 <div class="bkdesc">
                                    <p class="parasmall mb10">Order ID : {{objorder1.order_id}}</p>

                                    <p class="bkdate fontrobotomedium">
                                       {{getDateFormat(objorder1.appointment_date)}}
                                       <span class="green fontrobotobold clrgreen">
                                          Request Completed
                                       </span>
                                    </p>
                                  
                                 </div>
                                 <div class="view-info viewhistory">
                                    <div class="requestbx viewdetbx">
                                       <img src="assets/images/dashboard/profile.png" class="img-fluid" />
                                       <p class="fontrobotomedium history-para">Request Completed</p>
                                    </div>
                                    <button class="btn parrybtn booknow"
                                       (click)="NavigateToServiceCompleted(objorder1.order_id)"><span>View Details</span></button>
                                 </div>
                              </div>

                              <div class="orderrow requestcancel"
                                 *ngIf="objorder1.order_status.toLowerCase()=='cancelled'">
                                 <div class="bkdesc">
                                    <p class="parasmall mb10">Order ID : {{objorder1.order_id}}</p>
                                    <p class="bkdate fontrobotomedium ">
                                       {{getDateFormat(objorder1.appointment_date)}}
                                       <span class="green fontrobotobold clrred">
                                          Request Cancelled
                                       </span>
                                    </p>
                                 </div>
                                 <div class="view-info">
                                    <p class="fontrobotomedium history-para">Request cancelled by the customer</p>
                                 </div>
                              </div>
                           </div>


                        </div>
                     </ng-template>
                  </li>
               </ul>
               <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
   <div class="footerTop">
      <div class="container">
         <div class="fTopInner text-center">
            <a href="home.html"><img src="assets/images/home/logo.png" class="img-fluid" /></a>
            <p class="para">
               Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
               country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca
               Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary
               of the Roca Group in 2011.
            </p>
         </div>
      </div>
   </div>
   <div class="footerBtm ">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-8    fbtmenus">
              <ul class="nav menu_footer">
                 <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                 <li class="line"></li>
                 <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
              </ul>
            </div>
            <div class="col-lg-4  social-grid ">
              <p class="parasmall">Follow us on:</p>
              <ul class="social-menu">
                 <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                 <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
              </ul>
            </div>
         </div>
         <!--coprights-->
         <div class="copyright">
            <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
        </div>
      </div>
   </div>
</section>