<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a (click)="NavigateBack()"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span >Back</span></a>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">

            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!--Privacy policy-->
     <div class="Contentpanel clrwhite">
        <h2 class="h3title  clrwhite mb30 robotofontbold">TERMS AND CONDITIONS	
        </h2>
        <h4 class="h4title">1. CONTRACTUAL RELATIONSHIP
        </h4>
        <p class="para mb15">
            These Terms of Use ("Terms") govern the access or use by you, an individual, from within India of applications, websites, content, products, and services (the“Services”) made available by Roca Bathroom Products Private Limited (RBPPL), a private limited company established in India, having its registered office at ……………..and head office at ………………..(Parryware Bano).
        </p>  
        <h4 class="h4titile">
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.
        </h4>
        <p class="para mb15">
            Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and RBPPL. If you do not agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior written agreements with you.
         </p> 
        <p class="para mb15">
            Supplemental terms may apply to certain services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental terms are in addition to, and shall be deemed a part of, the terms for the purposes of the applicable services. Supplemental terms shall prevail over these terms in the event of a conflict with respect to the applicable services.
        </p> 
        <p class="para mb15">
            RBPPLmay restrict you from accessing or using the services, or any part of them, immediately, without notice, in circumstances where RBPPL reasonably suspects that:
        </p> 
        
        <ul class="contul">
            <li>
                You have, or are likely to, breach these Terms; and/or 
            </li>
            <li>
                You do not, or are likely not to, qualify, under applicable law or the standards and policies of RBPPL and its affiliates, to access and use the services.
            </li>
        </ul>
        <p class="para mb15">
            Immediately, where RBPPL reasonably suspects that:
        </p>
                
        <ul class="contul">
            <li>
                You have, or are likely to, materially breach these Terms; and/or 
            </li>
            <li>
                You do not, or are likely not to, qualify, under applicable law or the standards and policies of RBPPL and its affiliates, to access and use the services; or
            </li>
            <li>
                On 30 days' written notice to you, where RBPPL, acting reasonably, terminates these Terms or any Services for any legitimate business, legal or regulatory reason.
            </li>
        </ul>
        <p class="para mb15">
            Without limiting its other rights under these Terms, RBPPL may immediately restrict or deactivate your access to the Services if you breach the Community Guidelines at any time
        </p>
        <p class="para mb15">
            You may terminate these Terms at any time, for any reason.
        </p>
        <p class="para mb15">
            RBPPL may amend any policies or supplemental terms (including the Community Guidelines) related to the Services from time to time. RBPPL will provide you with at least 30 days' written notice in the event of a material change to any policies or supplemental terms that detrimentally affects your rights under these Terms. Amendments will be effective upon RBPPL’s posting of such amended policies or supplemental terms on the applicable Service. Your continued access or use of the Services after such posting, or after the expiry of the notice period (whichever is later), constitutes your consent to be bound by the Terms, as amended.
        </p>
        <h4 class="h4title">MODIFICATIONS TO TERMS OF USE AND/OR PRIVACY POLICY
        </h4>   
        <p class="para mb15">
            RBPPL reserves the right, in its sole discretion, to change, modify, or otherwise amend the Terms, and any other documents incorporated by reference herein for complying with legal and regulatory framework and for other legitimate business purposes, at any time, and RBPPL will post the amended Terms at the domain of www…………………………. /terms. It is your responsibility to review the Terms of Use for any changes and you are encouraged to check the Terms of Use frequently. Your use of the website (www………………………) (“ Website”) or applications (as defined below) (collectively “RBPPL Platform”) following the date that amendments to the Terms of Use take effect will signify your assent to and acceptance of any revised Terms of Use. If you do not agree to abide by these or any future Terms of Use, please do not use or access the RBPPL Platform.
        </p>
        <h4 class="h4title"> PRIVACY POLICY
        </h4> 
        <p class="para mb15">
            RBPPL has established a Privacy Policy that explains to users how their personal information is collected and used.
        </p>
        <p class="para mb15">
            The Privacy Policy is located at: …………... The Privacy Policy contains information about how users may seek access to and correction of their personal information held by RBPPL and how they may make a privacy complaint.
        </p>
        <p class="para mb15">
            The Privacy Policy is hereby incorporated into the Terms of Use set forth herein. Your use of this Website and/ or the Applications is governed by the Privacy Policy.
        </p>
        <p class="para mb15">
            RBPPL may disclose users’ personal information to third parties. RBPPL may provide to a claims processor or an insurer any necessary information (including your contact information) if there is a complaint, dispute or conflict, which may include an accident, involving you and a third party and such information or data is necessary to resolve the complaint, dispute or conflict. RBPPL may also provide to a law enforcement agency, statutory body, governmental agency and/or investigative agency any necessary information (including your contact information) if required by law or in furtherance of any investigation involving you and a third party and such information or data is necessary towards the enquiry/investigation that is being carried out by the said body and/or agency as the case may be.
        </p>
        <h4 class="h4title">2. THE SERVICES
        </h4>
        <p class="para mb15">
            RBPPL will provide the Services to you under this Agreement. The Services constitute the provision of a technology platform that enables you, as a user of RBPPL’s mobile application (each, an " Application") or Website to: (a) arrange and schedule home-based services with independent third party providers of those services, who have an agreement with RBPPL or its affiliates (“Third Party Providers”); and (b) facilitate payments to Third Party Providers for the services and receive receipts for those payments.
        </p>
        <p class="para mb15">
            The Services are made available solely for your personal, non-commercial use, unless RBPPL has agreed with you otherwise in a separate agreement. You acknowledge that RBPPL does not provide home-based services and that all such home-based services are provided by independent third party contractors who are not employed by RBPPL or any of its affiliates. RBPPL accepts liability for the Services and the RBPPL Platform that it provides to you subject to these Terms. Third Party Providers are responsible for the services they provide to you.
        </p>
        <h4 class="h4title">License.
        </h4>
        <p class="para mb15">
            Subject to your compliance with these Terms, RBPPL grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable licenseto: (i) access and use the RBPPL Platform on your personal device solely in connection with your use ofthe Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, non-commercial use. Any rights not expressly granted herein are reserved by RBPPL and RBPPL’s licensors.
        </p>
        <h4 class="h4title">Restrictions.
        </h4>
        <p class="para mb15">
            You may not:
        </p>
        <ul class="contul">
            <li>
                Remove any copyright, trademark or other proprietary notices from any portion of the Services;
            </li>
            <li>
                Reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by RBPPL.
            </li>
            <li>
                Decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law;
            </li>
            <li>
                Link to, mirror or frame any portion of the Services;
            </li>
            <li>
                Cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or 
            </li>
            <li>
                Attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks.
            </li>
        </ul>   
        <h4 class="h4title">Provision of the Services.
        </h4>
        <p class="para mb15">
            You acknowledge that portions of the Services may be made available under RBPPL’s various brands or request options. You also acknowledge that the Services may be made available under such brands or request options by or in connection with: (i) certain of RBPPL’s subsidiaries and affiliates; or (ii) independent Third Party Providers.
        </p> 
        <h4 class="h4title">Third Party Services and Content.
        </h4>
        <p class="para mb15">
            The Services may be made available or accessed in connection with third party services and content (including advertising) that RBPPL does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third party services and content. RBPPL does not endorse such third party services and content and in no event shall RBPPL be responsible or liable for any products or services of such third party providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their applicable international subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using Applications developed for Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively. These third party beneficiaries are not parties to this contract and are not responsible for the provision or support of the Services in any manner. Your access to the Services using these devices is subject to terms set forth in the applicable third party beneficiary’s terms of service.

        </p> 
        <h4 class="h4title">Ownership.
        </h4>
        <p class="para mb15">
            The Services and all rights therein are and shall remain RBPPL’s property or the property of RBPPL’s licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner RBPPL’s company names, logos, product and service names, trademarks or services marks or those of RBPPL’s licensors.

        </p> 
        <h4 class="h4title">3. YOUR USE OF THE USER ACCOUNT
        </h4>
        <p class="para mb15">
            In order to use most aspects of the Services, you must register for and maintain an active personal user Services account (" Account"). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account, unless a specific Service permits otherwise. Account registration requires you to submit to RBPPL certain personal information, such as your name, age, address, mobile number as well as at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and up-to date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired payment method on file, may result in your inability to access or use the Services. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by RBPPL in writing, you may only possess one Account.
        </p> 
        <h4 class="h4title">USER REQUIREMENTS AND CONDUCT
        </h4>
        <p class="para mb15">
            The Service is not available for use by persons under the age of 18. You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive services from Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when accessing or using the Services, and you may only access or use the Services for lawful purposes. You will not, in your use of the Services, cause nuisance, annoyance, inconvenience, or property damage, whether to the Third Party Provider or any other party. In certain instances you may be asked to provide proof of identity or other method of identity verification to access or use the Services, and you agree that you may be denied access to or use of the Services if you refuse to provide proof of identity or other method of identity verification.
        </p> 
        <h4 class="h4title">No Discrimination.
        </h4>
        <p class="para mb15">
            RBPPL prohibits discrimination against Third Party Providers based on sex, marital status, gender identity, age, race, religion, caste, national origin, disability, sexual orientation, or any other characteristic that may be protected under applicable law. Such discrimination includes, but is not limited to, any refusal to accept services based on any of these characteristics. If it is found that you have violated this prohibition you will lose access to the RBPPL platform. Applicable laws in certain jurisdictions may require and/or allow the provision of services by and for the benefit of a specific category of persons. In such jurisdictions, services provided in compliance with these laws and the relevant applicable terms are permissible.
        </p>
        <h4 class="h4title">Text Messaging.
        </h4>
        <p class="para mb15">
            By creating an Account, you agree that the Services may send you text (SMS) messages as part of the normal business operation of your use of the Services. You may opt-out of receiving text (SMS) messages from RBPPL at any time by informing RBPPL of your decision to stop receiving such text (SMS) messages. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the Services.
        </p>
        <h4 class="h4title">Promotional Codes.
        </h4>
        <p class="para mb15">
            RBPPL may, in RBPPL’s sole discretion, create promotional codes that may be redeemed for Account credit, or other features or benefits related to the Services and/or a Third Party Provider’s services, subject to any additional terms that RBPPL establishes on a per promotional code basis ("Promo Codes"). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise), unless expressly permitted by RBPPL; (iii) may be disabled by RBPPL at any time for any reason without liability to RBPPL; (iv) may only be used pursuant to the specific terms that RBPPL establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use. RBPPL reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that RBPPL reasonably determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.

        </p>
        <h4 class="h4title">USER PROVIDED CONTENT
        </h4>
        <p class="para mb15">
            RBPPL may, in RBPPL’s sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to RBPPL through the Services textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of support requests, and submission of entries for competitions and promotions (“User Content”).Any User Content provided by you remains your property. However, by providing User Content to RBPPL, you grant RBPPL a worldwide, perpetual, irrevocable, transferable, royalty-freelicense, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services andRBPPL’s business and on third-party sites and services), without further notice to or consent from you, and without the requirement of paymentto you or any other person or entity.
        </p>
        <p class="para mb15">
            You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases necessary to grant RBPPL the license to the User Content as set forth above; and (ii) neither the User Content nor your submission, uploading, publishing or otherwise making available of such User Content nor RBPPL’s use of the User Content as permitted herein will infringe, misappropriate or violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation; and (iii) you are not providing feedback for services performed by you in your capacity as a service provider on the platform.
        </p>
        <p class="para mb15">
            You agree to not provide User Content that is defamatory, grossly harmful, blasphemous, paedophilic, invasive of another’s privacy, ethnically objectionable, disparaging, relating or encouraging money laundering of gambling, libelous, hateful, racist, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by RBPPL in its sole discretion, whether or not such material may be protected by law. RBPPL may, but shall not be obligated to, review, monitor, or remove User Content, at RBPPL’s sole discretion and at any time and for any reason, without notice to you.

        </p>
        <h4 class="h4title">Network Access and Devices.
        </h4>
        <p class="para mb15">
            You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and you shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and RBPPL Platform and any updates thereto. RBPPL does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.

        </p>
        <h4 class="h4title">4. PAYMENT
        </h4>
        <p class="para mb15">
            You understand that use of the Services may result in charges to you for the services you receive from a Third Party Provider (" Charges"). After you have received services obtained through your use of the Service, RBPPL will facilitate your payment of the applicable Charges on behalf of the Third Party Provider as such Third Party Provider’s limited payment collection agent. Payment of the Charges in such manner shall be considered the same as payment made directly by you to the Third Party Provider.
        </p>
        <p class="para mb15">
            RBPPL shall charge you a convenience fee (“Fee”) for the Services provided by RBPPL to you. The Fee may be charged from you at the time of booking of services on the platform or upon the completion of service.
        </p>
        <p class="para mb15">
            Such Charges and Fee will be inclusive of applicable taxes where required by law. Charges and Fee paid by you are final and non-refundable, unless otherwise determined by RBPPL or required by the relevant Consumer Law legislation. Under the relevant Consumer Law legislation, you may be entitled to a refund for a major failure of the Services, or other remedies for a minor failure. You retain the right to request lower Charges or Fee from a Third Party Provider for services received by you from such Third Party Provider at the time you receive such services. RBPPL will respond accordingly to any request from a Third Party Provider to modify the Charges for a particular service.

        </p>
        <p class="para mb15">
            All Charges and Fee are due immediately and payment will be facilitated by RBPPL using the preferred payment method designated in your Account, after which RBPPL will send you a receipt by email. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that RBPPL may, as the Third Party Provider’s limited payment collection agent, use a secondary payment method in your Account, if available.

        </p>
        <p class="para mb15">
            As between you and RBPPL, RBPPL reserves the right to establish, remove and/or revise Charges for any or all services obtainable through the use of the Services at any time in RBPPL’s sole discretion. Further, you acknowledge and agree that Charges and Fees applicable in certain geographical areas may increase substantially during times of high demand. RBPPL will use reasonable efforts to inform you of Charges and Fees that may apply, provided that you will be responsible for Charges and Fees incurred under your Account regardless of your awareness of such Charges, Fees or the amounts thereof. RBPPL may from time to time provide certain users with promotional offers and discounts that may result in different amounts charged for the same or similar services obtained through the use of the Services, and you agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your use of the Services or the Charges applied to you. You may elect to cancel your request for services from a Third Party Provider at any time prior to such third Party Provider’s arrival, in which case you may be charged a cancellation fee.

        </p>
        <p class="para mb15">
            Additionally, the applicable taxes (whether direct or indirect) that are liable to be charged/ deducted on cancellation fee will charged to you by RBPPL.

        </p>
        <p class="para mb15">
            Further, RBPPL may from time to time offer subscription packages to you, wherein, in exchange for monetary consideration, additional benefits such as discounted services from Third Party Providers will be made available to you. The said benefits will only be available for a limited period as may be agreed with you in advance.

        </p>
        <p class="para mb15">
            This payment structure is intended to fully compensate the Third Party Provider for the services provided. RBPPL does not designate any portion of your payment as a tip or gratuity to the Third Party Provider. Any representation by RBPPL (on RBPPL’s website, in the Application, or in RBPPL’s marketing materials) to the effect that tipping is "voluntary," “not required,” and/or “included” in the payments you make for services provided is not intended to suggest that RBPPL provides any additional amounts, beyond those described above, to the Third Party Provider. You understand and agree that, while you are free to provide additional payment as a gratuity to any Third Party Provider who provides you with services obtained through the Service, you are under no obligation to do so. Gratuities are voluntary. After you have received services through the Service, you will have the opportunity to rate your experience and leave additional feedback about your Third Party Provider.

        </p>
        <p class="para mb15">
            It is clarified that the Charges and Fees (respectively) shall be made only for the services provided by the Third Party Provider to you and for the Services provided by RBPPL to you.

        </p>
        <h4 class="h4title">5. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY.

        </h4>
        <p class="para mb15">
            LIMITATION SUBJECT TO LOCAL CONSUMER LAW<br>
THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW, INCLUDING THE RELEVANT CONSUMER LAW LEGISLATION.

        </p>
        <h4 class="h4title">DISCLAIMER.
        </h4>
        <p class="para mb15">
            EXCEPT AS REQUIRED OF RBPPL UNDER THE CONSUMER GUARANTEES, THE SERVICES ARE PROVIDED "AS IS" AND “AS AVAILABLE.” RBPPL DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDINGANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, AND MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICESREQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. RBPPL DOES NOTGUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, INCLUDING THE RELEVANT CONSUMER LAW LEGISLATION.

        </p>
        <h4 class="h4title">LIMITATION OF LIABILITY            .
        </h4>
        <p class="para mb15">
            IF YOU ARE ACQUIRING THE SERVICES AS A CONSUMER, RBPPL 'S LIABILITY FOR A FAILURE TO COMPLY WITH A CONSUMER GUARANTEE IS LIMITED TO: IN THE CASE OF SERVICES SUPPLIED TO YOU AS PART OF THE SERVICES UNDER THIS AGREEMENT, THE SUPPLY OF THE RELEVANT SERVICES AGAIN, OR THE PAYMENT OF THE COST OF RESUPPLYING THE SERVICES.

        </p>
        <p class="para mb15">
            RBPPL 'S LIABILITY TO YOU FOR A BREACH OF ANY CONDITION, WARRANTY OR TERM OF THIS AGREEMENT THAT IS NOT A BREACH OF ACONSUMER GUARANTEE IS LIMITED IN THE FOLLOWING WAY: RBPPL SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF RBPPL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.RBPPL SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES ORYOUR INABILITYTO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IFRBPPL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. RBPPL SHALL NOT BE LIABLE OR DELAYOR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND RBPPL’S REASONABLE CONTROL. IN NO EVENT SHALL RBPPL’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED RUPEES THEN THOUSAND (INR 10,000).

        </p>
        <p class="para mb15">
            RBPPL’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE HOME-BASED SERVICES WITH THIRD PARTY BASED PROVIDERS, BUT YOU AGREE THAT RBPPL HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY HOME- SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.

        </p>
        <p class="para mb15">
            RBPPL WILL MAINTAIN A COMPLAINTS MANAGEMENT FRAMEWORK, AND WILL MANAGE THIS FRAMEWORK ON BEHALF OF THIRD PARTYPROVIDERS, IN A REASONABLE WAY AND IN ACCORDANCE WITH THE NON-EXCLUDABLE REQUIREMENTS OF THE RELEVANT CONSUMER LAW LEGISLATION.
        </p>
        <p class="para mb15">
            NOTWITHSTANDING ANYTHING CONTAINED IN THESE TERMS, RBPPL WILL NOT BE DEEMED TO BE IN DEFAULT OR BE RESPONSIBLE FOR DELAYSOR FAILURES IN PERFORMANCE RESULTING FROM ACTS BEYOND THE REASONABLE CONTROL OF RBPPL. SUCH ACTS SHALL INCLUDE BUT NOT BE LIMITED TO ACTS OF GOD, RIOTS, ACTS OF WAR, EPIDEMICS, PANDEMICS, TRADE EMBARGOES, FIRE, TYPHOONS, EARTHQUAKES, OTHER NATURAL DISASTERS. ETC.

        </p>
        <p class="para mb15">
            THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW, INCLUDING ANY OF THE NON-EXCLUDABLE REQUIREMENTS OF THE RELEVANT CONSUMER LAW LEGISLATION.

        </p>
        <h4 class="h4title">Indemnity
        </h4>
        <p class="para mb15">
            You agree to indemnify and hold RBPPL and its affiliates and their officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees) arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the services; (ii) your breach or violation of any of these Terms; (iii) RBPPL’s use of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers ("Losses ").

        </p>
        <p class="para mb15">
            Your liability under this clause shall be reduced proportionately if, and to the extent that, RBPPL directly caused or contributed to any such Losses.

        </p>
        <h4 class="h4title">6. GOVERNING LAW; ARBITRATION.
        </h4>
        <p class="para mb15">
            There are a number of mechanisms available to you to resolve any dispute, conflict, claim or controversy arising out of or broadly in connection with or relating to the Services or these Terms, including those relating to its validity, its construction or its enforceability or with the services provided by a Third Party Provider (any "Dispute"). RBPPL or its affiliates operate a complaints process to allow you to make complaints about RBPPL or Third Party Providers, and RBPPL or its affiliates also manage refunds to you in relation to those complaints. RBPPL or its affiliates will operate this complaints process in a reasonable manner. You agree and acknowledge that you must raise any Dispute with the Services or the services provided by a Third Party Provider as soon as is practicable, but no later than 48 hours from the time the Dispute arises. If you fail to raise a Dispute within 48 hours from the initial occurrence of such Dispute, RBPPL shall be under no obligation to resolve such Dispute. If a Dispute is reported more than 48 hours after the initial occurrence of the Dispute RBPPL shall in its sole discretion choose whether or not to attempt resolution of the Dispute.

        </p>
        <p class="para mb15">
            In addition, you may have rights to make a complaint to fair trading or consumer law bodies in relation to applicable consumer laws, including the non-excludable portions of the relevant Consumer Law legislation.

        </p>
        <p class="para mb15">
            Except as otherwise set forth in these Terms, these Terms shall be exclusively governed by and construed in accordance with the laws of India, excluding its rules on conflicts of laws. The Vienna Convention on the International Sale of Goods of 1980 (CISG) shall not apply. In the event of a Dispute, either party may file an action in the courts of India or pursue final and binding arbitration or other alternative dispute resolution as agreed upon by the parties. If either party elects to pursue final and binding arbitration, the venue of the arbitration shall be New Delhi, India.

        </p>
        <p class="para mb15">
            Any proceedings, including documents and briefs submitted by the parties, correspondence from a mediator, and correspondence, order and awards issued by an arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without the express written consent from the other party unless: (i) the disclosure to the third party is reasonably required in the context of conducting the mediation or arbitration proceedings; and (ii) the third party agrees unconditionally in writing to be bound by the confidentiality obligation set out in these Terms.
        </p>
        <h4 class="h4title">7. Other Provisions
        </h4>

        <ul class="contul">
            <li>
                Claims of Copyright Infringement.
            </li>
            <li>
                Claims of copyright infringement should be sent to ………………………...com.
            </li>
            <li>
                Grievance Officer.
            </li>
            <li>
                For the purposes of Consumer Protection Act, 2019 and rules and regulations thereunder, the grievance officer of RBPPL shall be:
            </li>
            <li>
                Mr/Ms. ……………………………………………
            </li>
            <li>
                RBPPL 
                …………………………………………….Address
                
            </li>
            <li>
                E-mail: ……………………………………………..
                
            </li>
            <li>
                Phone: …………………………………………
                
            </li>
            <li>
                Timings: 10 AM – 5 PM, Monday-Friday, excluding public holidays.

            </li>
        </ul>  
        <p class="para mb15">
            Notice.
        </p>
        <p class="para mb15">
            RBPPL may give notice by means of a general notice on the Services, electronic mail to your email address in your Account, or by written communication sent to your address as set forth in your Account. You may give notice to RBPPL by written communication to RBPPL 's address at ………………………………………………………………….
        </p>
        <p class="para mb15">
            General.
        </p>
        <p class="para mb15">
            You may not assign or transfer these Terms in whole or in part without RBPPL’s prior written approval. You give your approval to RBPPL for it to assign or transfer these Terms in whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of RBPPL’s equity, business or assets; or (iii) a successor by merger. No joint venture, partnership, employment or agency relationship exists between you, RBPPL or any Third Party Provider as a result of the contract between you and RBPPL or use of the Services.
        </p>
        <p class="para mb15">
            If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the legality, validity and enforceability of the other provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these Terms. These Terms, including any incorporated policies, constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. Nothing in this clause limits your rights as a consumer that cannot be excluded under applicable law, including the relevant Consumer Law legislation. In these Terms, the words “including” and “include” mean “including, but not limited to.”
        </p>
     </div>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm ">
        <div class="container">
           <div class="row fbtmRow no-gutters">
              <div class="col-lg-8    fbtmenus">
                <ul class="nav menu_footer">
                   <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                   <li class="line"></li>
                   <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                </ul>
              </div>
              <div class="col-lg-4  social-grid ">
                <p class="parasmall">Follow us on:</p>
                <ul class="social-menu">
                   <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                   <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                </ul>
              </div>
           </div>
           <!--coprights-->
           <div class="copyright">
              <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
          </div>
        </div>
     </div>
    </section>

