<div class="Top_spacing">
<div class="headerTop">
    <div class="container">
    <div class="headerTopInner">
     
       <div class="mlist LocationClick">
        <img src="assets/images/home/location.svg" class="img-fluid" />                 
         <p>Location</p>
         <a href="#">
             <span>Chennai</span>
         </a>
         <div class="servicearea">
           <p>
             Currently we are providing best services only to Chennai Location.
           </p>
       </div>
       </div>
     </div>
    </div>    
 </div>  
<header class="HeaderPanel">

    <div class="container" data-ng-init="checkloggedin()">
        <div class="HeaderInner">
            <div class="logo">
                <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                    <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                </a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#parrywareHeaderMenu"
                aria-controls="parrywareHeaderMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
                <span class="sr-only">Toggle navigation</span>
            </button>
            <div class="menuPanel" id="parrywareHeaderMenu">
            <div class="mlistpanel">
             
                <div class="mlist d-none d-lg-block d-sm-block">
                    <p>Location</p>
                    <a>
                        <img src="assets/images/home/location.svg" class="img-fluid" />
                        <span>Chennai</span>
                    </a>
                </div>
                <div class="headermenuline"></div>
                <div class="mlist dropdown">
                    <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                         <p *ngIf="!loggedin">Login/Sign up</p>
                        <a (click)="triggerModal(modalData)" *ngIf="!loggedin" class="loginClass">
                       
                       
                            <img src="assets/images/home/login.svg" class="img-fluid" />
                            <span>My Activity</span>
                        </a>
                        <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="menu.open()" *ngIf="loggedin"> 
                            <p *ngIf="loggedin">Welcome {{getCustomerName()}}</p>
                            <img src="assets/images/home/login.svg" class="img-fluid" />
                            
                            <span>My Activity</span></button>
  <div ngbDropdownMenu aria-labelledby="dropdownManual">
    <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
        class="img-fluid" /></span><a routerLink="/mydashboard" routerLinkActive="active">My Booking</a></button>
    <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
        class="img-fluid" /></span>Logout</button>
    
  </div>
                    </div>

                    <div  class="dropdown-menu" >
                        <a class="dropdown-item" href="#"></a>
                        <a class="dropdown-item" href="#"></a>
                        </div>
                </div>
                </div>
                <div class="booknow">
                    <button class="btn parrybtn hr_booknow" routerLink="/services" routerLinkActive="active"><span>Book Now</span></button>
                </div>
            </div>

        </div>
    </div>
</header>
<!-------------------------------Banner------------------>
<section class="homebannerpanel ContactPanel position-relative">
    <div class="bgs homebg"></div>
    <div class="homebgmobile">
    <div class="banText">
       
        <div class="container">
           
            <div class="banTxtInner">
                
                <h1 class="h1title fontrobotoblack clrwhite blueoverlay">
                 How Do I Contact <br /> Parryware Bano?
                </h1>
            </div>

        </div>
    </div>
    </div>
</section>

<!-----------------------------------Contact form-------------->
<section class="contactFormPanel">
<div class="container">
    <div class="row no-gutters cfp_row">
        <div class="col-lg-6 cfp_grid cfp_info">
            <div class="backoption">
                <a (click)="NavigateBack()"><img src="assets/images/dashboard/black.png" class="img-fluid back-img">
                    <span> Back</span></a>
                   
                </div>
            <p class="para fontrobotobold ">
                Our customer experience team uses email to handle the majority of cases. Our support team is available from 8 am to 8 pm (Monday to Saturday) , and we usually get back to you in just a few hours! The more detailed you are in your request, the faster we'll be able to help you.
            </p>
           <a class="cturl" pageScroll routerLink="/home" href="#faq">
            Click here for some information on frequently asked topics.
            </a>
            <p class="paralarge fontrobotoblack clrblack mb-2">Registered Office</p>
            <address class="para addrinfo">
            The Heritance No 41, Haddows Road,<br>
            Nungambakkam, Chennai - 600006<br>
            Tamil Nadu, India
            </address>
            <p class="paralarge fontrobotoblack clrblack mb-2">Need Help</p>
            <p class="para clrblack ">Call us in case you face any issue in our service</p>
            <div class="nhcontact chcontact">
                <a href="tel:+1800 274 6060">
                   <img src="assets/images/dashboard/call-1.svg"/>
                   <span>1800 274 6060</span>
                </a>
                <a href="tel:+7301274274 ">
                  <img src="assets/images/dashboard/whatsapp.png" style="max-width:20px;"/>
                  <span>7301 274 274</span>
               </a>
             </div>
        </div>
        <div class="col-lg-6 cfp_grid cfp_form">
            <div class="cfpformInner">
                <p class="paralarge clrblack fontrobotobold  mb-4">
                <img src="assets/images/contact/contactus-icon.png" class="img-fluid"/>
                Contact Us</p>
                <form [formGroup]="contactUsForm">
                <div class="row ctform-row">
                    <div class="col-lg-12 ctform-grid">
                        <label>Name:</label>
                        <input type="text" class="ctformstyle" required formControlName="contactName" formGroupName="contactName"
                        [ngClass]="{ 'is-invalid': submitted3 && f3.contactName.errors }" />
                        <div *ngIf="submitted3 && f3.contactName.errors" class="invalid-feedback">
                            <div *ngIf="f3.contactName.errors.required">Customer Name is required</div>
                            <div *ngIf="f3.contactName.errors.pattern ">
                               Enter valid name</div>
                         </div>
                    </div>
                    <div class="col-lg-6 ctform-grid">
                        <label>Phone Number:</label>
                        <input class="ctformstyle" (keypress)="keyPress($event)" required type="text"
                       formControlName="contactmobilenumber"
                        [ngClass]="{ 'is-invalid': submitted3 && f3.contactmobilenumber.errors }"/>
                        <div *ngIf="submitted3 && f3.contactmobilenumber.errors" class="invalid-feedback">
                            <div *ngIf="f3.contactmobilenumber.errors.required">Phone number is required</div>
                            <div
                                *ngIf="f3.contactmobilenumber.errors.pattern || f3.contactmobilenumber.errors.maxlength || f3.contactmobilenumber.errors.minlength">
                                Phone number must be at least 10 numbers</div>
                        </div>
                    </div>     
                    <div class="col-lg-6 ctform-grid">
                        <label>Email:</label>
                        <input type="text" class="ctformstyle" formControlName="contactEmail"
                        [ngClass]="{ 'is-invalid': submitted3 && f3.contactEmail.errors }"/>
                        <div *ngIf="submitted3 && f3.contactEmail.errors" class="invalid-feedback">
                            <div *ngIf="f3.contactEmail.errors.required">Customer Email ID is required</div>
                            <div *ngIf="f3.contactEmail.errors.pattern ">
                               Enter valid mail id</div>
                         </div>
                    </div>        
                    <div class="col-lg-12 ctform-grid">
                        <label>Message:</label>
                        <input type="text" class="ctformstyle cttextareastyle" required formControlName="contactMessage"/>
                    </div>  
                     <div class="col-lg-6 ctform-grid">
                        <ngx-recaptcha2 #captchaElem
                        [siteKey]="siteKey" 
                        formControlName="recaptcha">
                      </ngx-recaptcha2>
                    </div>        
                     <div class="col-lg-6 ctform-grid ctbtn">
                        <a class="gonextbtn btn booknow ctsubmitbtn" (click)="storeMessage()">Submit 
                            <img src="assets/images/dashboard/continue.png" class="img-fluid"/>
                        </a>
                    </div>                                                                              
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
</section>

<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
    <div class="footerTop">
       <div class="container">
          <div class="fTopInner text-center">
             <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
             <p class="para">
                Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
             </p>
          </div>
       </div>
    </div>
    <div class="footerBtm ">
        <div class="container">
           <div class="row fbtmRow no-gutters">
              <div class="col-lg-8    fbtmenus">
                <ul class="nav menu_footer">
                   <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                   <li class="line"></li>
                   <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                   <li class="line"></li>
                   <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                </ul>
              </div>
              <div class="col-lg-4  social-grid ">
                <p class="parasmall">Follow us on:</p>
                <ul class="social-menu">
                   <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                   <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                </ul>
              </div>
           </div>
           <!--coprights-->
           <div class="copyright">
              <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
          </div>
        </div>
     </div>
 </section>
</div>
<!-----------------------------Login Popup------------------------>
<ng-template #modalData let-modal >


    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="popupInner">
            <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
            <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
            <form [formGroup]="registerForm" (ngSubmit)="getotp(modalOtp)">
                <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your mobile number</p>
                <div class="loginpanel">
                    <input class="txtbx pop_txtbx mb30" (keypress)="keyPress($event)" required type="text"
                        placeholder="Enter your mobile number" formControlName="phonenumber"
                        [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                    <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                        <div *ngIf="f.phonenumber.errors.required">Phone number is required</div>
                        <div
                            *ngIf="f.phonenumber.errors.pattern || f.phonenumber.errors.maxlength || f.phonenumber.errors.minlength">
                            Phone number must be at least 10 numbers</div>
                    </div>
                    <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--------------------------OTP Screen------------------->
<ng-template #modalOtp let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="popupInner">
            <h2 class="popuptitle clrblack fontrobotobold mb50">Enter verification code</h2>
            <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
                <div class="otppanel">
                    <p class="parasmall clrblack mb30">We have sent a 4 digit OTP to your mobile number</p>
                    <div class="otpformgroup mb30">
                        <input id="otp1" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength required (keypress)="keyPress($event)" minlength="1" maxlength="1" 
                  formControlName="first" [ngClass]="{ 'is-invalid': submitted1 && f1.first.errors }" />
              <input id="otp2" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="second" [ngClass]="{ 'is-invalid': submitted1 && f1.second.errors }" />
              <input id="opt3" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="third" [ngClass]="{ 'is-invalid': submitted1 && f1.third.errors }" />
              <input id="otp4"  type="text" class="txtbx pop_txtbx otptxt"  (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="fourth" [ngClass]="{ 'is-invalid': submitted1 && f1.fourth.errors }" />
                    </div>
                    <div *ngIf="submitted1 && f1.first.errors" class="invalid-feedback">
                        <div *ngIf="f1.first.errors.required">
                            Otp is required</div>
                        <div *ngIf="f1.first.errors.pattern || f1.first.errors.maxlength || f1.first.errors.minlength">
                            Otp is not valid</div>
                        <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                    </div>
                    <div *ngIf="submitted1 && f1.second.errors" class="invalid-feedback">
                        <div *ngIf="f1.second.errors.required">
                            Otp is required</div>
                        <div
                            *ngIf="f1.second.errors.pattern || f1.second.errors.maxlength || f1.second.errors.minlength">
                            Otp is not valid</div>
                        <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                    </div>
                    <div *ngIf="submitted1 && f1.third.errors" class="invalid-feedback">
                        <div *ngIf="f1.third.errors.required">
                            Otp is required</div>
                        <div *ngIf="f1.third.errors.pattern || f1.third.errors.maxlength || f1.third.errors.minlength">
                            Otp is not valid</div>
                        <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                    </div>
                    <div *ngIf="submitted1 && f1.fourth.errors" class="invalid-feedback">
                        <div *ngIf="f1.fourth.errors.required">
                            Otp is required</div>
                        <div
                            *ngIf="f1.fourth.errors.pattern || f1.fourth.errors.maxlength || f1.fourth.errors.minlength">
                            Otp is not valid</div>
                        <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                    </div>

                    <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--------------------------Name and Email ID------------------->
<ng-template #contactData let-modal>
    <div class="modal-header">
       <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
       </button>
    </div>
    <div class="modal-body">
       <div class="popupInner">
          <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
          <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
          <form [formGroup]="contactForm" (ngSubmit)="storeContact()">
             <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your Name</p>
             <div class="loginpanel">
                <input class="txtbx pop_txtbx mb30" required type="text" placeholder="Enter Your Name"
                   formControlName="customername" [ngClass]="{ 'is-invalid': submitted1 && f2.customername.errors }">
                <div *ngIf="submitted1 && f2.customername.errors" class="invalid-feedback">
                   <div *ngIf="f2.customername.errors.required">Customer Name is required</div>
                   <div *ngIf="f2.customername.errors.pattern ">
                      Enter valid name</div>
                </div>
 
                <input class="txtbx pop_txtbx mb30" required type="text" placeholder="Enter Your Email"
                   formControlName="customeremailid"
                   [ngClass]="{ 'is-invalid': submitted1 && f2.customeremailid.errors }">
                <div *ngIf="submitted1 && f2.customeremailid.errors" class="invalid-feedback">
                   <div *ngIf="f2.customeremailid.errors.required">Customer Email ID is required</div>
                   <div *ngIf="f2.customeremailid.errors.pattern ">
                      Enter valid mail id</div>
                </div>
                <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
             </div>
          </form>
       </div>
    </div>
 </ng-template>
 <!-----------------------------End of contact details------------------------>