import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Product } from './../product';
import { CartService } from './../cart.service'
import {ActivatedRoute,Router} from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-renovation',
  templateUrl: './renovation.component.html',
  styleUrls: ['./renovation.component.css']
})
export class RenovationComponent implements OnInit {

  @ViewChild('contactData') contactData : TemplateRef<any>;
  
  cattabactive = 1;
  subcattabactive = 1;
  cartHasItems = false;
  cartHasNoItems = true

  loggedin=false;
  
  cartitems: any;
  totalitems = 0;
  totalprice = 0;
  submitted = false;
  submitted1=false;
  submitted2=false;

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;
    
  private modalRef: NgbModalRef;
  private otpmodalRef: NgbModalRef;
  private contactmodalRef:NgbModalRef;

  defaultimage="assets/images/dashboard/wm-repairs.png";

  unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  registerForm = new FormGroup({ phonenumber: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('')});
  inwarrantyflag=false;
  outofwarrantyflag=true;

  constructor(private titleService:Title,private router:Router, private route: ActivatedRoute, private formBuilder: FormBuilder,private modalService: NgbModal, private apiService: ApiService, private cart: CartService) {
    this.titleService.setTitle("Parryware Bano | Services");
   }
   hiwslides = [
    { count: "01", maintext: "Talk to our experts over a call" },
    { count: "02", maintext: "Personalize the material" },
    { count: "03", maintext: "Finalize the design" },
    { count: "04", maintext: "Make Payment" },

  ];
  hiwslideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "infinite": false, "dots": true, "arrows": false };
  ngOnInit(): void {
    console.log(window.sessionStorage.getItem("warrantytype"));
    if(window.sessionStorage.getItem("warrantytype")===undefined || window.sessionStorage.getItem("warrantytype")===null)
    {
    this.route.queryParams.subscribe(params => {
      if(params.type==='inwarranty')
      {
        this.inwarrantyflag=true;
      }
    })
  }
  else
  {
    if(window.sessionStorage.getItem("warrantytype")==="IN")
    this.inwarrantyflag=true;
  }
    
    if(localStorage.getItem("step1")===undefined)
      localStorage.setItem("step1","false");
      else
      this.step1=JSON.parse((localStorage.getItem("step1")));
      if(localStorage.getItem("step2")===undefined)
    localStorage.setItem("step2","false");
    else
    this.step2=JSON.parse((localStorage.getItem("step2")));
    if(localStorage.getItem("step3")===undefined)
    localStorage.setItem("step3","false");
    else
    this.step3=JSON.parse((localStorage.getItem("step3")));
    if(localStorage.getItem("step4")===undefined)
    localStorage.setItem("step4","false");
    else
    this.step4=JSON.parse((localStorage.getItem("step4")));
    if(localStorage.getItem("step5")===undefined)
    localStorage.setItem("step5","false");
    else
    this.step5=JSON.parse((localStorage.getItem("step5")));

    this.checkloggedin();

    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
        Validators.pattern(this.emailPattern)]]
    });


    
 console.log(window.sessionStorage.getItem("cart"));

    if(window.sessionStorage.getItem("cart")!=null)
    {
    this.cart=JSON.parse(window.sessionStorage.getItem("cart"));
    console.log(this.cart);
    if(this.cart.items.length>0)
    {
      this.checkCartForProducts();
     
      for(var item of this.cart.items)
      {
        this.totalitems+=item.quantity;
        this.totalprice+=(item.price* item.quantity)
      }
    }
  }
  else
  {
    this.cart.items=[];
    this.cart.addonitems=[];
    this.cartHasItems=false;
    this.cartHasNoItems=true;
  }
    
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }


  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
    });


  }

  validateOtp() {
    this.submitted1=true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);
    var getsession = window.sessionStorage.getItem("otp");
    //if (otpentered === getsession) {

      this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
        console.log(data);
        localStorage.setItem("CustomerID",data.customer_id);
        localStorage.setItem("mobile",this.registerForm.get('phonenumber').value);
        if(data.cust_name!="" && data.cust_name!=null)
        {
          localStorage.setItem("CustomerName",data.cust_name);
          localStorage.setItem("CustomerEmail",data.email);      
          this.loggedin=true;
        }
        else
        {
          this.contactmodalRef = this.modalService.open(this.contactData,{ windowClass: 'parrymodal' });
        }
        this.registerForm.setValue({ phonenumber: '' })
        this.otpForm.setValue({first:'',second:'',third:'',fourth:''})
        this.submitted = true;
        this.checkloggedin();
        this.otpmodalRef.close();
      })
   // }

  }


  checkloggedin()
  {
    if(window.localStorage.getItem("CustomerName")!=null)
    {
      if(window.localStorage.getItem("CustomerName")!="")
       this.loggedin=true
    }
  }

  checkCartForProducts() {
    if (this.cart.items.length >0 ) {
      this.cartHasNoItems = false;
      this.cartHasItems = true;
    }
    else {
      this.cartHasNoItems = true;
      this.cartHasItems = false;
    }
  }

  triggerModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'parrymodal' });
  }
  openModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'descModal' });
  }
  closeModal(content: any) {
    this.modalRef.close();
  }



  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }
  gotopage(){
    window.scrollTo(0, 0);
  }

  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  
}
