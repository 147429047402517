<div class="DashboardInner">
   <div class="container">
      <div class="innercontainer">
         <!-------------------------------Header------------------>
         <header class="dashboardHeader">
            <div class="row dsRow no-gutters">
               <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
                  <p class="paraxl fontrobotoblack clrwhite  d-none d-lg-block d-sm-block bookingImg">
                     <a><img
                         src="assets/images/dashboard/back.png"
                         class="img-fluid"
                       />
                       <span>Back</span></a>
                   </p>
                  <p class="paraxl fontrobotoblack clrwhite">
                     <img src="assets/images/dashboard/confirmed.svg" class="d-block d-lg-none d-sm-none bookingImg" />
                     <span class="d-block d-lg-none d-sm-none">Service Completed</span>
                  </p>
               </div>
               <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                  <div class="dsheaderlist">
                     <div class="mlist dropdown">
                        <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                           <p>{{getCustomerName()}}</p>
         
                           <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                              (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                                 class="img-fluid" />
                              <span>My Activity</span></button>
                           <div ngbDropdownMenu aria-labelledby="dropdownManual">
                              <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                       class="img-fluid" /></span><a routerLink="/mydashboard"
                                    routerLinkActive="active">My Booking</a></button>
                              <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-3.png"
                                       class="img-fluid" /></span>Logout</button>
         
                           </div>
                        </div>
                     </div>
                     <div class="dslogo">
                        <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                           <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <section class="dsSteps">
            <!------------------Inner section--------->
            <div class="dsInnerpanel ">
               <div class="dsbx dsbookingpanel">
                  <div class="dsInnerTitle justify-content-start ServiceCompleted d-none d-lg-block d-sm-block">
                     <p class="paraxl fontrobotobold clrwhite">
                        <img src="assets/images/dashboard/white-tick.png" class="img-fluid confirmimg" />
                        Service Completed
                     </p>
                  </div>
                  <div class="row no-gutters bookingrow">
                     <div class="col-lg-7 bookinggrid bkleft">
                        <p class="paramedium fontrobotobold clrblack borderbtm">
                           Service completed on {{selectday}} {{datestring}} at {{starttime}}
                        </p>
                        <div class="bk-details border-top-bottom">
                           <div class="row addrconfirmrow no-gutters">
                              <div class="col-lg-6  col-sm-6 confirgrid">
                                 <p class="parasmall fontrobotobold clrblack">
                                    Service Location
                                 </p>
                                 <address>
                                    {{addselected.housename_no}},{{addselected.street_name}} <br>
                                    {{addselected.area}}, Chennai, <br>
                                    Tamil Nadu {{addselected.pincode}}, India
                                 </address>
                              </div>
                              <div class="col-lg-6 col-sm-6 confirgrid">
                                 <p class="parasmall fontrobotobold clrblack">
                                    Timings
                                 </p>
                                 <address>
                                    {{selectday}} {{datestring}} <br>
                                    {{selectedslot}}
                                 </address>
                              </div>
                           </div>
                        </div>
                        <div class="btnrequestpanel  borderbtm d-flex">
                           <div class="ratingdesc">
                              <img src="assets/images/dashboard/profile.png" class="img-fluid" />
                              <div class="ratinginfor">
                                 <p class="parasmall clrblack fontrobotobold">{{plumbername}}</p>
                                 <p class="parasmall clrblack d-flex" *ngIf="servicerating!=null">You rated
                                    <span class="ratingblue">
                                       <img src="assets/images/dashboard/star.svg" class="img-fluid" />
                                       {{servicerating}}</span>
                                 </p>
                              </div>
                           </div>
                           <a class="btn btnrequest d-flex align-items-center justify-content-center"
                              (click)="triggerRateModel(rateModal)">{{getButtonText()}}</a>
                        </div>
                        <div class="nhpanel">
                           <p class="paralarge fontrobotobold">
                              Need Help
                           </p>
                           <div class="nhcontact">
                              <a href="tel:+1800 274 6060">
                                 <img src="assets/images/dashboard/call-1.svg"/>
                                 <span>1800 274 6060</span>
                              </a>
                              <a href="tel:+7301274274 ">
                                <img src="assets/images/dashboard/whatsapp.png" style="max-width:20px;"/>
                                <span>7301 274 274</span>
                             </a>
                           </div>
                        </div>

                        <p class="parasmall">Get in touch with us if you face any issue in our service</p>
                     </div>
                     <div class="col-lg-5 bookinggrid bkpayment">
                        <p class="paramedium text-center fontrobotobold clrblack bookinTitle">
                           Order Summary
                        </p>
                        <div class="costrow">
                           <div class="cs_inn" *ngFor="let objpdt of product">
                              <p>{{objpdt.category_name}}<br>{{objpdt.product_name}}</p>
                              <p class="priceamt"><span>&#8377;</span> {{objpdt.price}}</p>
                           </div>
                           <div class="cs_inn" *ngFor="let objaddon of addon">
                              <p>{{objaddon.addon_name}}</p>
                              <p  class="priceamt"><span>&#8377;</span> {{objaddon.price}}</p>
                           </div>
                        </div>
                        <div class="costrow">
                           <div class="cs_inn">
                              <p class="paramedium">CC Expenses</p>
                              <p class="paramedium priceamt  ">&#8377; {{orderDetail.CCExpensesAmt }}</p>
                           </div>
                        </div>
                        <div class="costrow">
                           <div class="cs_inn">
                              <p class="paramedium">Other Expenses</p>
                              <p class="paramedium priceamt">&#8377; {{orderDetail.OthersBillAmt }}</p>
                           </div>
                        </div>
                        <div class="costrow">
                           <div class="cs_inn">
                              <p class="paramedium">Spares Expenses</p>
                              <p class="paramedium priceamt">&#8377; {{orderDetail.SparesBillAmt }}</p>
                           </div>
                        </div>
                        <div class="total ">
                           <div class="cs_inn">
                              <p class="paramedium fontrobotobold ">Total</p>
                              <p class="paramedium priceamt fontrobotobold ">&#8377; {{totalamt }}</p>
                           </div>
                        </div>
                        <div class="download">
                           <a (click)="openPDF()" style="text-align:right">Download</a>
                           </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
   <div class="footerTop">
      <div class="container">
         <div class="fTopInner text-center">
            <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid" /></a>
            <p class="para">
               Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
               country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca
               Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary
               of the Roca Group in 2011.
            </p>
         </div>
      </div>
   </div>
   <div class="footerBtm bggrey">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-4  fbtcopyrights">
               <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
            </div>
            <div class="col-lg-8  fbtmenus">
               <ul class="nav menu_footer">
                  <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                  <li class="line"></li>
                  <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
              </ul>
           </div>
         </div>
      </div>
   </div>
</section>



<!-----------------------------Rating Popup------------------------>
<ng-template #rateModal let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="rateinnerpanel">
         <p class="paraxl fontrobotobold clrblack mb20">Rate your service experience</p>
         <img src="assets/images/dashboard/profile.png" class="img-fluid mb20" />
         <p class="paramedium clrblack fontrobotobold">{{plumbername}}</p>
         <span>Plumber</span>
      </div>
     
      <form [formGroup]="rateForm" (ngSubmit)="storeRating()">
         <div class="ratepanel">
            <p class="fontrobotobold para">On Time Arrival </p>
            <ngb-rating [formControl]="ctrl" [max]="5" [ngClass]="{ 'is-invalid': submitted && f.ctrl.errors }">
            </ngb-rating>
            <span id="ratetextbind">{{ctrl.value}}</span>
            <div *ngIf="submitted && f.ctrl.errors" class="invalid-feedback">
               <div *ngIf="f.ctrl.errors.required">Value is required</div>
            </div>
         </div>
         <div class="ratepanel">
            <p class="fontrobotobold para">Plumber Behaviour </p>
            <ngb-rating  [formControl]="ctrl1" [max]="5" [ngClass]="{ 'is-invalid': submitted && f.ctrl1.errors }">
            
            </ngb-rating>
            <span id="ratetextbind">{{ctrl1.value}}</span>

            <div *ngIf="submitted && f.ctrl1.errors" class="invalid-feedback">
               <div *ngIf="f.ctrl1.errors.required">Value is required</div>
            </div>
         </div>
         <div class="ratepanel">
            <p class="fontrobotobold para">Service Rating </p>
            <ngb-rating [formControl]="ctrl2" [max]="5" [ngClass]="{ 'is-invalid': submitted && f.ctrl2.errors }">
            </ngb-rating>
            <span id="ratetextbind">{{ctrl2.value}}</span>
            <div *ngIf="submitted && f.ctrl2.errors" class="invalid-feedback">
               <div *ngIf="f.ctrl2.errors.required">Value is required</div>
            </div>
         </div>
         <div class="rateformpanel">
            <div class="form-group">
               <label>What could have been improved</label>
               <select class="addrinput" [formControl]="ddlImprove"
                  [ngClass]="{ 'is-invalid': submitted && ddlImprove.errors }">
                  <option>Timely Service Delivery</option>
               </select>
               <div *ngIf="submitted && ddlImprove.errors" class="invalid-feedback">
                  <div *ngIf="ddlImprove.errors.required">Value is required</div>
               </div>
            </div>
            <div class="form-group">
               <label>Comments</label>
               <textarea [formControl]="txtComments" placeholder="Your review helps them improve and get better work"
                  class="addrinput addrtxtarea"
                  [ngClass]="{ 'is-invalid': submitted && txtComments.errors }"></textarea>
               <div *ngIf="submitted && txtComments.errors" class="invalid-feedback">
                  <div *ngIf="txtComments.errors.required">Value is required</div>
               </div>
            </div>
            <div class="gonextpanel nomargin">
               <button class="gonextbtn btn booknow popbtn fullwidth">Submit Feedback
               </button>
            </div>
         </div>
      </form>
   </div>
</ng-template>

<!-------------------------------Invoice Download------------------>

<div class="DashboardInner" style="display: none;">
   <div class="container">
      <div class="innercontainer">
<table class="invoicetable parrywareTable" id="invoice">
   <tr>
    
              <td colspan="2"><b>Parryware</b></td>
              <td colspan="3"align="right"><b>Estimate</b></td>
      
   </tr>
 <tr>
   <td colspan="2"><p class="paramedium fontrobotobold ">
               Service By
            </p>
            <address class="para">
               <b>Metro Solutions</b><br>
               No:1, 8th Street,
               Gopalapuram, Chennai - 600 086 <br>
               Tamil Nadu, India
            </address></td>
            <td colspan="3"><p class="paramedium fontrobotobold ">
               Billing Address:
            </p>
            <address class="para">
               <b>{{getCustomerName()}}</b><br>
               {{addselected.housename_no}},{{addselected.street_name}} ,
               {{addselected.area}}, Chennai, <br>
               Tamil Nadu {{addselected.pincode}}, India
            </address></td>
     
 </tr>
 <tr>
    <td colspan="5">
      <p class="para mb30 ">GSTIN/33ABTPN6592J1ZW</p>
    </td>
 </tr>
 <tr>
    <td colspan="5">
      <p class=" para ">
         <span>Order Number:</span>
         <span><b>{{orderDetail.order_number}}</b></span>
     </p>
     <p class="para ">
      <span>Order Date:</span>
      <span><b>{{dateselected}}</b></span>
  </p>
    </td>
 </tr>
  <tr>
       <th>Slno</th>
       <th>Service Details</th>
       <th>Unit Price in Rs.</th>
       <th>Qty</th>
       <th>Net Amount in Rs.</th>
      </tr>
   <tr>
      <td colspan="5"><b>Product Service Charges</b></td>
   </tr>
       <tr *ngFor="let objpdt of product; let num=index">
           <td>{{num + 1}}</td>
           <td>{{objpdt.category_name}}-{{objpdt.product_name}}</td>
           <td align="right">{{objpdt.price | number : '1.2'}}</td>
           <td align="right">{{objpdt.quantity | number : '1.2'}}</td>
           <td align="right">{{objpdt.price * objpdt.quantity | number : '1.2'}}</td>
       </tr>
       <tr>
         <td colspan="5"><b>Add On Service Charges</b></td>
      </tr>
       <tr *ngFor="let objaddon of addon; let num1=index">
        <td>{{num1 + 1}}</td>
        <td>{{objaddon.addon_name}}</td>
        <td align="right">{{objaddon.price | number : '1.2'}}</td>
        <td align="right">1.00</td>
        <td align="right">{{objaddon.price | number : '1.2'}}</td>
    </tr>
    <tr>
      <td colspan="5"><b>Other Charges</b></td>
   </tr>
    <tr>
      <td></td>
      <td><b>CC Expenses <span *ngIf="orderDetail.CCExpensesBillNo!=null">{{orderDetail.CCExpensesBillNo}}</span></b></td>
      <td></td>
      <td></td>
      <td align="right"><b>{{orderDetail.CCExpensesAmt | number : '1.2' }}</b></td>
  </tr>
  <tr>
   <td></td>
   <td><b>Other Expenses  <span *ngIf="orderDetail.OthersBillNo!=null">{{orderDetail.OthersBillNo}}</span></b></td>
   <td></td>
   <td></td>
   <td align="right"><b>{{orderDetail.OthersBillAmt | number : '1.2'}}</b></td>
</tr>
<tr>
   <td></td>
   <td><b>Spare Expenses  <span *ngIf="orderDetail.SparesBillNo!=null">{{orderDetail.SparesBillNo}}</span></b></td>
   <td></td>
   <td></td>
   <td align="right"><b>{{orderDetail.SparesBillAmt | number : '1.2' }}</b></td>
</tr>
      <tr>
          <td></td>
          <td><b>Total</b></td>
          <td></td>
          <td></td>
          <td align="right"><b>{{totalamt + DiscountAmt | number : '1.2' }}</b></td>
      </tr>
      <tr>
          <td></td>
          <td><b>Discount</b></td>
          <td></td>
          <td></td>
          <td align="right"><b>{{DiscountAmt | number : '1.2'}}</b></td>
      </tr>
      <tr>
          <td></td>
          <td><b>Final Service Value</b></td>
          <td></td>
          <td></td>
          <td align="right"><b>{{totalamt | number : '1.2' }}</b></td>
      </tr>
  
</table>
</div>
</div>
</div>