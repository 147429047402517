<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a routerLink="/selectaddress" routerLinkActive="active"><img src="assets/images/dashboard/back.png" class="img-fluid"/></a>
                <span class="d-none d-lg-block d-sm-block">Book Your Service</span>
                <span class="d-block d-lg-none d-sm-none">Select Date / Time</span>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">
            <div class="mlist dropdown">
               <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                  <p>{{getCustomerName()}}</p>

                  <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                     (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                        class="img-fluid" />
                     <span>My Activity</span></button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                     <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                              class="img-fluid" /></span><a routerLink="/mydashboard"
                           routerLinkActive="active">My Booking</a></button>
                     <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                              class="img-fluid" /></span>Logout</button>

                  </div>
               </div>
            </div>
            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!---------------------------------steps-------------------->
     <section class="dsSteps">
      <div class="Stlist">
         <ul class="nav">
             <li class="st-active"><a routerLink="/services" routerLinkActive="active" [ngClass]="{'adisabled':!step1}">01</a>
                 <span>Select Services</span>
             </li>
             <div class="stline"></div>
             <li class="st-active"><a routerLink="/addon" routerLinkActive="active" [ngClass]="{'adisabled':!step2}">02</a>
                 <span>Select Add-on</span>
             </li>
             <div class="stline"></div>
             <li class="st-active"><a routerLink="/selectaddress" routerLinkActive="active" [ngClass]="{'adisabled':!step3}">03</a>
                 <span>Address Information</span>
             </li>
             <div class="stline"></div>
             <li class="st-active"><a routerLink="/selectservicedate" routerLinkActive="active" [ngClass]="{'adisabled':!step4}">04</a>
                 <span>Select Date / Time</span>
             </li>
             <div class="stline"></div>
             <li><a routerLink="/confirmbooking" routerLinkActive="active" [ngClass]="{'adisabled':!step5}">05</a>
                 <span>Confirmation</span>
             </li>
         </ul>
     </div>
        <!------------------Inner section--------->
        <div class="dsInnerpanel">
        <div class="dsbx">
           <div class="dsInnerTitle mb30">
              <a routerLink="/selectaddress" routerLinkActive="active"> < Back</a>
              <p class="paraxl fontrobotobold clrblack ">When would you like us to serve you?</p>
           </div>
           <p class="text-center para mb50 dltitle">
              <img src="assets/images/dashboard/calendar.svg" class="img-fluid"/>
              <span>Select date of service</span>
           </p>
           <ul class="nav dateList slickformobile slickcarouelstyle">
              <li *ngFor="let objdate of availabledates; let i = index" [ngClass]="{'activedl' : i==selecteddate && objdate.status, 'disabledl': !objdate.status}" (click)="changeselectedDate(i,objdate.date)">
                <div *ngIf="i==0">Today</div>
                <div *ngIf="i==1">Tomorrow</div>
                <div *ngIf="i>1">{{objdate.day_of_week}}</div>
                <span>{{objdate.day}}</span>
                
              </li>
 
             </ul>
           
        </div>
        
        <div class="sltime_panel">
          <p class="text-center para mb50 dltitle">
             <img src="assets/images/dashboard/time.svg" class="img-fluid"/>
             <span>Select time of service</span>
          </p>
           <ul class="nav timelist">
              <li *ngFor="let objtime of availabletimes; let j=index" [ngClass]="{'activetime' : j==selectedtime && objtime.status, 'disabledtime': !objtime.status}" (click)="changeselectedTime(j)" >{{objtime.slot}}</li>
              
           </ul>
           <div class="gonextpanel">
             <a (click)="gotopage()" routerLink="/confirmbooking" routerLinkActive="active" class="gonextbtn btn booknow popbtn fullwidth">Next 
                <img src="assets/images/dashboard/continue.png" class="img-fluid"/>
             </a>
          </div>
        </div>

       </div>
     </section>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm ">
         <div class="container">
            <div class="row fbtmRow no-gutters">
               <div class="col-lg-8    fbtmenus">
                 <ul class="nav menu_footer">
                    <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                    <li class="line"></li>
                    <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                    <li class="line"></li>
                    <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                 </ul>
               </div>
               <div class="col-lg-4  social-grid ">
                 <p class="parasmall">Follow us on:</p>
                 <ul class="social-menu">
                    <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                    <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                 </ul>
               </div>
            </div>
            <!--coprights-->
            <div class="copyright">
               <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
           </div>
         </div>
      </div>
    </section>
