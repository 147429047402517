import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { PageScrollService } from 'ngx-page-scroll-core';
import { Product } from './../product';
import { CartService } from './../cart.service'
import { ProductType } from '../order-model';
import {Title} from "@angular/platform-browser";


export type customerDetail = { customer_id: string, cust_name: string, email: string }

export type slide = { content: string, price: number, img: string, productid: number, catid: number, pdtname: string }

export type catpdttype = { product_id: number, category_id: number, category: string, name: string, image: string, price: number, best_selling: boolean, highlight: boolean };

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('contactData') contactData: TemplateRef<any>;
  active = 1;
  activetab = 1;
  loggedin = false;
  cartitems: any;
  totalitems = 0;
  totalprice = 0;
  cat10pdts: catpdttype[] = [];
  warrantytype = "";
  selectlocation = new FormControl('');
  years: any = [];
  fileToUpload: File | null = null;
  parrywarebrand: boolean = false;
  otherbrand: boolean = false;
  parrywarranty: boolean = false;
  pdttype: ProductType = {
    date_of_purchase: "",
    parryware_product: false,
    multibrand_product: false,
  };
  chkparrybrand = new FormControl();
  chkotherbrand = new FormControl();
  chkjhonson = new FormControl();
  fuWarranty = new FormControl();
  month = new FormControl();
  year = new FormControl();
  brand:string="";
  brandselected:boolean=false;
brandcount:number=0;

  unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  registerForm = new FormGroup({ phonenumber: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('') });

  submitted = false;
  submitted1 = false;
  submitted2 = false;
  pdtsubmitted=false;

  private modalRef: NgbModalRef;
  private otpmodalRef: NgbModalRef;
  private contactmodalRef: NgbModalRef;
  private brandmodalRef: NgbModalRef;

  objcustomer: customerDetail;
  slides: slide[] = [];
  constructor(private titleService:Title,private pageScrollService: PageScrollService, private cart: CartService, private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router) {
    this.titleService.setTitle("Parryware Bano | Home");
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  isShowcovid = true;
  opensidebar() {
    this.isShowcovid = !this.isShowcovid;
  }
  closesidebar() {
    this.isShowcovid = !this.isShowcovid;
  }
  triggerModal(content: any) {

    this.modalRef = this.modalService.open(content, { windowClass: 'parrymodal ' });
  }

  addItemsToCart(pdtid: number, catid: number, price1: number, pdtname: string, pdtcatname: string) {
    this.openModal('outwarranty',false);
    window.scrollTo(0, 0);
    let objpdt: Product = {
      product_id: pdtid,
      product_name: pdtname,
      category_name: pdtcatname,
      category_id: catid,
      price: price1, quantity: 1, type: "outofwarranty"
    }
    this.totalitems += 1;
    this.totalprice += price1;

    let index = this.cart.items.findIndex(ob => ob.product_id === pdtid && ob.type === "outofwarranty");
    console.log(index);
    if (index < 0) {
      this.cart.items.push(objpdt);;
    }
    else
      this.cart.items[index].quantity += 1;
    console.log(this.cart.items);
    window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
    window.sessionStorage.setItem("warrantytype", "OUT");
    this.router.navigate(["services"]);
  }



  handleFileInput(e: any) {
    this.fileToUpload = e.target.files.item(0);
    console.log(this.fileToUpload);
  }

  uploadFile() {


    this.apiService.postFile(this.fileToUpload).subscribe((data: any) => {
      console.log(data);
      window.sessionStorage.setItem("filename", data.file);
      alert("Document Uploaded Successfully");
    })


  }

  slideConfig = {
    "slidesToShow": 5, "slidesToScroll": 5, "infinite": false, "dots": true, "arrows": true,
    responsive: [

      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        }
      }
    ]
  };

  hiwslides = [
    { count: "01", maintext: "Pick Your Service", desc: "Pick the specific service you need from the plethora of services we offer." },
    { count: "02", maintext: "Pick Your Product", desc: "Pick the product you need service with from our range of products." },
    { count: "03", maintext: "Pick A Time Slot", desc: "Pick the date and time of service that is convenient to you." },
    { count: "04", maintext: "Enjoy The Service", desc: "Chill and relax, while our experts arrive at your place to provide you top-notch services!" },

  ];
  hiwslideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "infinite": false, "dots": true, "arrows": false };



  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  @ViewChild('brandpopup') brandpopup: TemplateRef<any>;
  ngAfterViewInit() {

    this.selectlocation.setValue('Chennai');
    console.log(window.sessionStorage.getItem("warrantytype"));
    if (window.sessionStorage.getItem("warrantytype") !== undefined || window.sessionStorage.getItem("warrantytype") !== null)
      this.warrantytype = window.sessionStorage.getItem("warrantytype");

    if (this.warrantytype === "OUT")
      this.active = 2;
  }

  openModal(type: any,navigateflag:boolean) {
    localStorage.setItem("navigateflag",navigateflag.toString());
    this.warrantytype = type;
    console.log(this.warrantytype);
    this.brandmodalRef = this.modalService.open(this.brandpopup, { windowClass: 'parrymodal  brandpopup' });
  }

  checkselection(e:any)
  {
    if(!e.target.checked && this.brandselected && this.brandcount>0)
    {
      if(this.brandcount>0)
        this.brandcount--;
        if(this.brandcount===0)
        {
          this.brandselected=false;
        }
    }
    if(e.target.checked && this.brandselected && this.brandcount===1)
    {
      alert("You can select only one brand at a time");
      e.target.checked=false;
    }
      if(e.target.checked && !this.brandselected)
      {
        this.brandcount++;
        this.brandselected=true;
      }
  }

  NavigatetoServices() {
    this.pdtsubmitted=true;
    this.parrywarebrand = false;
    this.otherbrand = false;
    if(this.chkotherbrand.value)
    {
      this.brand="Roca";
      this.otherbrand = true;
      this.pdtsubmitted=false;
    }
    else if (this.chkparrybrand.value)
    {
      this.brand="Parryware"
      this.parrywarebrand = true;
      this.pdtsubmitted=false;
    }
    else if(this.chkjhonson.value)
    {
      this.brand="Johnson Peddar"; 
      this.otherbrand = true;
      this.pdtsubmitted=false;
    }
   
    console.log(this.pdtsubmitted);
    if(this.pdtsubmitted)
        return;
    else
    {

    let purchasedate1 = this.month.value + "-" + this.year.value;

    this.pdttype = {
      date_of_purchase: purchasedate1,
      parryware_product: this.parrywarebrand,
      multibrand_product: this.otherbrand
    }
    console.log(this.pdttype);
    window.sessionStorage.setItem("producttype", JSON.stringify(this.pdttype));
    window.sessionStorage.setItem("brand",this.brand)
    this.brandmodalRef.close();
    if(localStorage.getItem("navigateflag")==="true")
    {
    if (this.warrantytype === "inwarranty")
      this.router.navigate(["/services"], { queryParams: { type: 'inwarranty' } })
    else
      this.router.navigate(["/services"]);
    }
  }
  }
  ngOnInit(): void {
    this.pdttype = {
      date_of_purchase: "",
      parryware_product: false,
      multibrand_product: false,
    };
    window.sessionStorage.setItem("producttype", JSON.stringify(this.pdttype));
    this.years = [];
    let year1 = new Date().getFullYear();
    for (var i = year1 - 10; i <= year1; i++)
      this.years.push(i);
    this.checkloggedin();

    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
      Validators.pattern(this.emailPattern)]]
    });
    this.apiService.getProducts(0).subscribe((data: any) => {
      let catallpdts = data.product;
      let cathpdts = catallpdts.filter((item: { highlight: boolean; }) => item.highlight === true)
      this.cat10pdts = catallpdts.filter((item: { category_id: number; }) => item.category_id === 10)
      for (let i = 0; i < cathpdts.length; i++) {
        let slide1: slide = {
          content: cathpdts[i].category,
          price: cathpdts[i].price,
          productid: cathpdts[i].product_id,
          img: cathpdts[i].image,
          catid: cathpdts[i].category_id,
          pdtname: cathpdts[i].name
        }
        this.slides.push(slide1);

      }
    })

  }

  calllocation() {
    if (this.selectlocation.value === "Other Locations") {
      this.selectlocation.setValue('Chennai');
      alert("Please Call 7301 274 274 for your requirements");
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }


  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content, { windowClass: 'parrymodal ' });
    });


  }

  validateOtp() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);

    var getsession = window.sessionStorage.getItem("otp");
    // if (otpentered === getsession) {

    this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
      console.log(data);
      localStorage.setItem("CustomerID", data.customer_id);
      localStorage.setItem("mobile", this.registerForm.get('phonenumber').value);
      if (data.cust_name != "" && data.cust_name != null) {
        localStorage.setItem("CustomerName", data.cust_name);
        localStorage.setItem("CustomerEmail", data.email);
        this.loggedin = true;
      }
      else {
        this.contactmodalRef = this.modalService.open(this.contactData, { windowClass: 'parrymodal' });
      }
      this.registerForm.setValue({ phonenumber: '' })
      this.otpForm.setValue({ first: '', second: '', third: '', fourth: '' })
      this.submitted = true;
      this.checkloggedin();
      this.otpmodalRef.close();
    })
    // }

  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }

  checkloggedin() {
    if (window.localStorage.getItem("CustomerName") != null) {
      if (window.localStorage.getItem("CustomerName") != "")
        this.loggedin = true
    }
  }

  storeContact() {
    this.submitted2 = true;
    if (this.contactForm.invalid)
      return;
    localStorage.setItem("CustomerName", this.contactForm.get('customername').value.toString());
    localStorage.setItem("CustomerEmail", this.contactForm.get('customeremailid').value.toString());

    this.objcustomer = {
      customer_id: localStorage.getItem("CustomerID"),
      cust_name: this.contactForm.get('customername').value.toString(),
      email: this.contactForm.get('customeremailid').value.toString()
    }

    this.apiService.postCustomerDetail(this.objcustomer).subscribe((data: any) => {
      this.checkloggedin();
      this.contactmodalRef.close();
    })

  }

  NavigateTo() {
    this.router.navigate(["services"]);
    window.scrollTo(0, 0);
  }
  gotopage() {
    window.scrollTo(0, 0);
  }

  isShow = false;

  closeheader() {
    this.isShow = !this.isShow;
  }

  logout() {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
    this.loggedin = false;
  }

  addItem() {
    this.openModal('outwarranty',false);
    console.log(this.cat10pdts);
    let objpdt: Product = {
      product_id: this.cat10pdts[0].product_id,
      product_name: this.cat10pdts[0].name,
      category_name: this.cat10pdts[0].category,
      category_id: this.cat10pdts[0].category_id,
      price: this.cat10pdts[0].price, quantity: 1, type: "outofwarranty"
    }
    let index = this.cart.items.findIndex(ob => ob.product_id === this.cat10pdts[0].product_id && ob.type === "outofwarranty");
    console.log(index);
    if (index < 0) {
      this.cart.items.push(objpdt);;
    }
    console.log(this.cart.items);
    window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
    this.router.navigate(["selectaddress"]);

  }

}
