<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
          <!-------------------------------Header------------------>
          <header class="dashboardHeader">
             <div class="row dsRow no-gutters">
                <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel ">
                   <p class="paraxl fontrobotoblack clrwhite">
                      <img src="assets/images/dashboard/confirmed.svg" class="d-block d-lg-none d-sm-none bookingImg" />
                      <span class="d-block d-lg-none d-sm-none">Payment Successfully</span>
                   </p>
                </div>
                <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                   <div class="dsheaderlist">
                      <div class="mlist dropdown">
                         <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                            <p>{{getCustomerName()}}</p>
 
                            <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                               (focus)="menu.open()"><img src="assets/images/home/login.svg"
                               class="img-fluid" />
                               <span>My Activity</span></button>
                            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                               <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                        class="img-fluid" /></span><a routerLink="/mydashboard"
                                     routerLinkActive="active">My Booking</a></button>
                               <button ngbDropdownItem (click)="logout()"><span><img
                                        src="assets/images/home/drp-icon-3.png"
                                        class="img-fluid" /></span>Logout</button>
 
                            </div>
                         </div>
                      </div>
                      <div class="dslogo">
                         <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                            <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                         </a>
                      </div>
                   </div>
                </div>
             </div>
          </header>
 
          <!---Innersection-->
 <div class="dsInnerpanel ">
    <div class="dsbx dsbookingpanel">
       <div class="dsInnerTitle justify-content-start">
          <p class="paraxl fontrobotobold clrblack">
             <img src="assets/images/dashboard/confirmed.svg" class="d-none d-lg-block d-sm-block img-fluid confirmimg" />
             <span class="d-none d-lg-block d-sm-block">Payment Successfully</span>
          </p>
       </div>
       <div class="row no-gutters bookingrow">
          <div class="col-lg-7 bookinggrid bkleft">
        
             <div class="nhpanel">
                <p class="paralarge fontrobotobold">
                   Need Help
                </p>
                <div class="nhcontact">
                   <a href="tel:+1800 274 6060">
                      <img src="assets/images/dashboard/call-1.svg"/>
                      <span>1800 274 6060</span>
                   </a>
                   <a href="tel:+7301274274 ">
                     <img src="assets/images/dashboard/whatsapp.png" style="max-width:20px;"/>
                     <span>7301 274 274</span>
                  </a>
                </div>
             </div>
 
             <p class="parasmall">Get in touch with us if you face any issue in our service</p>
          </div>
          <div class="col-lg-5 bookinggrid bkpayment">
            <p class="paramedium  text-center fontrobotobold clrblack bookinTitle">
                Order Summary
             </p>
             <div class="costrow">
                <div class="cs_inn">
                   <p>Order Id</p>
                   <p class="priceamt">{{orderid}}</p>
                </div>
                <div class="cs_inn">
                    <p>Tracking Id</p>
                    <p class="priceamt">{{trackingid}}</p>
                 </div>
                 <div class="cs_inn">
                    <p>Bank Ref Number</p>
                    <p class="priceamt">{{refnumber}}</p>
                 </div>
                 <div class="cs_inn">
                    <p>Amount</p>
                    <p class="priceamt"><span>&#8377;</span> {{amount}}</p>
                 </div>

                <div class="cs_inn" *ngFor="let objaddon of addon">
                   <p>{{objaddon.addon_name}}</p>
                   <p  class="priceamt">
                      <span>&#8377;</span> {{objaddon.price}}
                  </p>
                  <a class="deletecart" (click)="removeAddon(objaddon.addon_id)">
                     <img src="assets/images/home/delete.png"/>
                   </a>
                </div>
             </div>
          </div>
       </div>
       
 
    </div>
 </div>
      </div></div></div>
 
 
 
 <!-------------------------------Footer Panel------------------>
 <section class="Footerpanel">
    <div class="footerTop">
       <div class="container">
          <div class="fTopInner text-center">
             <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png"
                   class="img-fluid" /></a>
             <p class="para">
                Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
                country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca
                Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary
                of the Roca Group in 2011.
             </p>
          </div>
       </div>
    </div>
    <div class="footerBtm ">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-8    fbtmenus">
              <ul class="nav menu_footer">
                 <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                 <li class="line"></li>
                 <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                 <li class="line"></li>
                 <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
              </ul>
            </div>
            <div class="col-lg-4  social-grid ">
              <p class="parasmall">Follow us on:</p>
              <ul class="social-menu">
                 <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                 <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
              </ul>
            </div>
         </div>
         <!--coprights-->
         <div class="copyright">
            <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
        </div>
      </div>
   </div>
 </section>

 